import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import ImageDisplay from '@/components/atom/ImageDisplay';
import { cn, formatNumberWithCommas } from '@/lib/utils';
import { IStepThreeFormItem } from '@/module/projectsList/project.interface';

interface SortableItemProps {
  item: IStepThreeFormItem;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const SortableItem = ({ item, onClick }: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id || '',
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDragging ? 'grabbing' : 'grab',
    zIndex: isDragging ? 999 : 'auto',
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div
        className={cn(
          'mb-6 cursor-pointer rounded-2xl border border-outline-200 p-2 shadow-project-card transition-transform duration-200 hover:border-primary-500 hover:shadow-project-card-hover',
          isDragging && 'scale-105',
        )}
        role="button"
        onClick={onClick}
      >
        <ImageDisplay
          className="mb-2.5 aspect-square w-full rounded-xl object-cover"
          url={item.thumbnailUrl}
          alt="img"
        />
        <h1 className="line-clamp-1 text-sm font-bold text-neutral-850">{item.returnName}</h1>
        <p className="line-clamp-2 h-[35px] text-[13px] font-medium text-secondary-400">{item.content}</p>
        <div className="my-2 flex h-[20px] w-full items-center justify-between rounded-xl bg-[#E5F6FF] px-2">
          <img src={'/assets/images/icon_token.png'} alt="img" />
          <p className="text-[13px] font-bold text-neutral-850">{formatNumberWithCommas(item.apAmount)}AP</p>
        </div>
        <button
          id="drag"
          className={cn('flex w-full justify-center py-2', isDragging && 'cursor-grabbing')}
          {...attributes}
          {...listeners}
        >
          <img src="/assets/images/drag-icon.svg" alt="drag" />
        </button>
      </div>
    </div>
  );
};
