import { TextField as Text } from '@radix-ui/themes';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { SortOrderEnum } from '@/common/enums';
import { handleException } from '@/common/exceptions/exception-handling';
import { formatFullDate } from '@/common/helpers';
import * as toast from '@/common/helpers/toast';
import { ITableColumn } from '@/common/types';
import AppButton from '@/components/atom/Button/Button';
import { AppTable } from '@/components/atom/Table';
import TextField from '@/components/atom/TextField/TextField';
import Heading1 from '@/components/common/Heading1';
import ConfirmPopup from '@/components/modals/ConfirmPopup';
import { useFilter } from '@/contexts';
import { useLoading } from '@/hooks/useLoading';
import { formatNumberWithCommas } from '@/lib/utils';
import { UsersApi } from '@/module/users/api';
import { IUsersBody } from '@/module/users/request/users.interface';
import { IUsersResponse } from '@/module/users/response/users.interface';

interface UsersPageProps {}

const UsersPage: React.FC<UsersPageProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentSearch = searchParams.get('search') || '';
  const currentSortField = (searchParams.get('sortField') as keyof IUsersResponse) || 'createdAt';
  const currentSortOrder = (searchParams.get('sortOrder') as SortOrderEnum) || SortOrderEnum.Desc;
  const [users, setUsers] = useState<IUsersResponse[]>([]);
  const [searchTerm, setSearchTerm] = useState(currentSearch);
  const [sortField, setSortField] = useState<keyof IUsersResponse>(currentSortField);
  const [sortOrder, setSortOrder] = useState<SortOrderEnum>(currentSortOrder);
  const { openLoading, closeLoading } = useLoading();
  const { updateCurrentFilters } = useFilter<IUsersBody>();

  const debouncedSearchUsers = useRef(
    debounce(async (term: string) => {
      await fetchUsers({ search: term });
    }, 500),
  ).current;

  const userDeletionPopupConfig = useMemo(
    () => ({
      title: '以下のユーザーを削除しますか？',
      cancelText: 'キャンセル',
    }),
    [],
  );

  const columns: ITableColumn<IUsersResponse>[] = [
    {
      field: 'nickName',
      label: '名前',
      headerClassName: 'w-[15%]',
      bodyClassName: 'w-[15%] truncate',
      sortable: true,
    },
    {
      field: 'email',
      label: 'メールアドレス',
      headerClassName: 'w-[25%]',
      bodyClassName: 'w-[25%] truncate',
      sortable: true,
    },
    {
      field: 'userName',
      label: 'ユーザー名',
      headerClassName: 'w-[15%]',
      bodyClassName: 'w-[15%]',
      sortable: true,
    },
    {
      field: 'apBalance',
      label: '保有AP量',
      headerClassName: 'w-[12%]',
      bodyClassName: 'w-[12%] truncate',
      sortable: true,
      valueGetter: (row) => (row.apBalance ? `${formatNumberWithCommas(row.apBalance)}AP` : '無し'),
    },
    {
      field: 'createdAt',
      label: '登録日',
      headerClassName: 'w-[15%]',
      bodyClassName: 'w-[15%]',
      sortable: true,
      valueGetter: (row) => formatFullDate(row.createdAt),
    },
    {
      label: '詳細',
      headerClassName: 'w-[9%]',
      bodyClassName: 'w-[9%]',
      valueGetter: (row) => <AppButton text="詳細" size="s-md" onClick={() => navigateToUserDetails(row.id)} />,
    },
    {
      label: '削除',
      headerClassName: 'w-[9%]',
      bodyClassName: 'w-[9%]',
      valueGetter: (row) => (
        <ConfirmPopup
          popupConfig={{
            ...userDeletionPopupConfig,
            description: `ユーザー名：${row.userName}`,
            customeBtn: (
              <AppButton
                onClick={() => handleUserDeletion(row.id)}
                text={'削除'}
                size="sm"
                custome="bg-red-450 px-0 flex-1"
              />
            ),
          }}
        >
          <AppButton
            text="削除"
            size="s-md"
            variant="outline"
            custome="border-red-450 text-red-450 lg:focus:text-red-450 lg:hover:text-red-450 lg:hover:bg-transparent lg:focus:bg-transparent"
          />
        </ConfirmPopup>
      ),
    },
  ];

  const updateUrlParams = (params: IUsersBody) => {
    const newParams = new URLSearchParams(searchParams);

    if (params.search !== undefined) {
      newParams.set('search', params.search);
    }
    if (params.sortField !== undefined) {
      newParams.set('sortField', params.sortField);
    }
    if (params.sortOrder !== undefined) {
      newParams.set('sortOrder', params.sortOrder);
    }

    navigate(`/users?${newParams.toString()}`);
  };

  const fetchUsers = async (params: IUsersBody) => {
    try {
      openLoading();
      const response = await UsersApi.getAllUsers(params);
      setUsers(response.data);
    } catch (error) {
      handleException(error);
    } finally {
      closeLoading();
    }
  };

  const navigateToUserDetails = (userId: string) => {
    navigate(`/users/preview/${userId}`);
    updateCurrentFilters({
      search: searchTerm,
      sortField,
      sortOrder,
    });
  };

  const handleUserDeletion = async (userId: string) => {
    try {
      openLoading();
      await UsersApi.deleteUser(userId);
      await fetchUsers({
        search: searchTerm,
        sortField,
        sortOrder,
      });
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const handleSort = (newSortField: keyof IUsersResponse, newSortOrder: SortOrderEnum) => {
    setSortField(newSortField);
    setSortOrder(newSortOrder);

    const params = {
      search: searchTerm,
      sortField: newSortField,
      sortOrder: newSortOrder,
    };

    updateUrlParams(params);
    fetchUsers(params);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    updateUrlParams({ search: newSearchTerm });
    debouncedSearchUsers(newSearchTerm);
  };

  const handleExportCSV = async () => {
    try {
      openLoading();
      await UsersApi.exportCsv();
      toast.showSuccess('CSV 出力が完了しました');
    } catch (error) {
      handleException(error);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    fetchUsers({
      search: searchTerm,
      sortField: currentSortField,
      sortOrder: currentSortOrder,
    });
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearchUsers.cancel();
    };
  }, [debouncedSearchUsers]);

  return (
    <div className="px-20 py-16">
      <Heading1 text="ユーザー情報一覧" />
      <div className="my-10 flex w-full gap-10">
        <div className="flex-1">
          <TextField placeholder="メールアドレス検索" value={searchTerm} onChange={(value) => handleSearch(value)}>
            <Text.Slot></Text.Slot>
            <Text.Slot>
              <img src="/assets/images/search-icon.svg" alt="" />
            </Text.Slot>
          </TextField>
        </div>
        <AppButton text="CSV 出力" size="s-md" onClick={handleExportCSV} />
      </div>
      <div className="">
        <AppTable columns={columns} rows={users} sortField={sortField} sortOrder={sortOrder} onSort={handleSort} />
      </div>
    </div>
  );
};

export default UsersPage;
