import * as yup from 'yup';

import { ERROR_MESSAGE, generateErrorLimitMsg } from '@/common/constants/common.constants';

export const STEP_ONE_SCHEMA = yup.object().shape({
  title: yup
    .string()
    .min(5, generateErrorLimitMsg(5, 50))
    .max(50, generateErrorLimitMsg(5, 50))
    .required(ERROR_MESSAGE.required),
  summary: yup
    .string()
    .min(30, generateErrorLimitMsg(30, 200))
    .max(200, generateErrorLimitMsg(30, 200))
    .required(ERROR_MESSAGE.required),
  thumbnailUrl: yup.mixed<string | File>().required(ERROR_MESSAGE.required),
  targetApAmount: yup
    .string()
    .typeError(ERROR_MESSAGE.wrongFormat)
    .test('min', ERROR_MESSAGE.wrongFormat, (value) => {
      const num = Number(value);
      return num >= 0;
    })
    .required(ERROR_MESSAGE.required),
  startDate: yup.string().required(ERROR_MESSAGE.required),
  endDate: yup.string().required(ERROR_MESSAGE.required),
  practitionerName: yup
    .string()
    .min(3, generateErrorLimitMsg(3, 30))
    .max(30, generateErrorLimitMsg(3, 30))
    .required(ERROR_MESSAGE.required),
  practitionerProfile: yup
    .string()
    .min(50, generateErrorLimitMsg(50, 300))
    .max(300, generateErrorLimitMsg(50, 300))
    .required(ERROR_MESSAGE.required),
  practitionerThumbnailUrl: yup.mixed<string | File>().required(ERROR_MESSAGE.required),
  xUrl: yup.string().url(ERROR_MESSAGE.wrongFormat),
  instagramUrl: yup.string().url(ERROR_MESSAGE.wrongFormat),
});
