import { Dispatch, FC, SetStateAction, useState } from 'react';

import { SortOrderEnum } from '@/common/enums';
import { handleException } from '@/common/exceptions/exception-handling';
import { displayData, formatFullDate } from '@/common/helpers';
import * as toast from '@/common/helpers/toast';
import { ITableColumn } from '@/common/types';
import AppButton from '@/components/atom/Button/Button';
import { AppTable } from '@/components/atom/Table';
import { useLoading } from '@/hooks/useLoading';
import { InterviewsApi } from '@/module/interviews/api';
import { ColumnInterviewsId, EInterviewStatus, INTERVIEW_STATUS, PAYMENT_STATUS } from '@/module/interviews/constants';
import { IInterviewsResponse } from '@/module/interviews/response/interviews.interface';
import ConfirmInterviewPopup from './ConfirmInterviewPopup';
import ConfirmPaymentPopup from './ConfirmPaymentPopup';
import InformationPopup from './InformationPopup';

interface TableInterviewsProps {
  data: IInterviewsResponse[];
  sortField: keyof IInterviewsResponse;
  sortOrder: SortOrderEnum;
  setData: Dispatch<SetStateAction<IInterviewsResponse[]>>;
  onSort: (newSortField: keyof IInterviewsResponse, newSortOrder: SortOrderEnum) => void;
}

const TableInterviews: FC<TableInterviewsProps> = ({ data, sortField, sortOrder, setData, onSort }) => {
  const [currentData, setCurrentData] = useState<any>({});
  const [openPopup, setOpenPopup] = useState(false);
  const [openMaxPopup, setOpenMaxPopup] = useState(false);
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  const [openInformationPopup, setOpenInformationPopup] = useState(false);
  const { openLoading, closeLoading } = useLoading();

  const columns: ITableColumn<IInterviewsResponse>[] = [
    {
      field: 'returnName',
      label: 'リターン名',
      headerClassName: 'w-[26%]',
      bodyClassName: 'w-[26%] truncate',
      sortable: true,
      valueGetter: (row) => displayData(row.returnName),
    },
    {
      field: 'email',
      label: 'メールアドレス',
      headerClassName: 'w-[26%]',
      bodyClassName: 'w-[26%] truncate',
      sortable: true,
      valueGetter: (row) => displayData(row.email),
    },
    {
      field: 'nickName',
      label: '応援者名',
      headerClassName: 'w-[15%]',
      bodyClassName: 'w-[15%] cursor-pointer',
      sortable: true,
      valueGetter: (row) => (
        <span onClick={() => handleShowInterviewsStatusPopup(row, ColumnInterviewsId.SUPPORTER_NAME)}>
          {displayData(row.nickName)}
        </span>
      ),
    },
    {
      field: 'interviewDate',
      label: '申請⽇',
      headerClassName: 'w-[23%]',
      bodyClassName: 'w-[23%]',
      sortable: true,
      valueGetter: (row) => formatFullDate(row.interviewDate),
    },
    {
      label: '合否',
      headerClassName: 'w-[15%]',
      bodyClassName: 'w-[15%]',
      valueGetter: (row) =>
        row.interviewStatus === EInterviewStatus.PENDING ? (
          <AppButton
            onClick={() => handleShowInterviewsStatusPopup(row, ColumnInterviewsId.PASS_FAIL)}
            text="判定"
            size="s-md"
            custome="text-sm"
          />
        ) : (
          displayData(INTERVIEW_STATUS?.[row.interviewStatus as keyof typeof INTERVIEW_STATUS])
        ),
    },
    {
      label: '⽀払',
      headerClassName: 'w-[15%]',
      bodyClassName: 'w-[15%]',
      valueGetter: (row) =>
        row.interviewStatus === EInterviewStatus.APPROVED ? (
          <AppButton
            text="未払い"
            size="s-md"
            onClick={() => handleShowInterviewsStatusPopup(row, ColumnInterviewsId.PAYMENT)}
            custome="text-sm"
          />
        ) : (
          displayData(PAYMENT_STATUS?.[row.interviewStatus as keyof typeof PAYMENT_STATUS])
        ),
    },
  ];

  const updateInterviewStatus = (status: EInterviewStatus) => {
    const newData = data.map((item) => {
      if (item.id === currentData.id) {
        return {
          ...item,
          interviewStatus: status,
        };
      }
      return item;
    });
    setData([...newData]);
  };

  const handleConfirmInterviewsStatusPopup = async () => {
    try {
      openLoading();
      const response = await InterviewsApi.approveInterview(currentData.id);
      if (response.status === 200) {
        updateInterviewStatus(EInterviewStatus.APPROVED);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const handleFailureInterviewsStatusPopup = async () => {
    try {
      openLoading();
      const response = await InterviewsApi.cancelInterview(currentData.id);
      if (response) {
        updateInterviewStatus(EInterviewStatus.DENIED);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const handleConfirmPayment = async () => {
    try {
      openLoading();
      const response = await InterviewsApi.paid(currentData.id);
      if (response) {
        updateInterviewStatus(EInterviewStatus.PAID);
      }
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      setOpenPaymentPopup(false);
      closeLoading();
    }
  };

  const handleShowInterviewsStatusPopup = (data: IInterviewsResponse, type: ColumnInterviewsId) => {
    if (ColumnInterviewsId.PASS_FAIL === type) setOpenPopup(true);
    if (ColumnInterviewsId.PAYMENT === type) setOpenPaymentPopup(true);
    if (ColumnInterviewsId.SUPPORTER_NAME === type) setOpenInformationPopup(true);
    setCurrentData(data);
  };

  return (
    <>
      <AppTable columns={columns} rows={data} sortField={sortField} sortOrder={sortOrder} onSort={onSort} />
      <ConfirmInterviewPopup
        openMaxPopup={openMaxPopup}
        openPopup={openPopup}
        setOpenMaxPopup={setOpenMaxPopup}
        setOpenPopup={setOpenPopup}
        currentData={currentData}
        handleApprove={handleConfirmInterviewsStatusPopup}
        handleFailure={handleFailureInterviewsStatusPopup}
      />
      <ConfirmPaymentPopup
        openPopup={openPaymentPopup}
        setOpenPopup={setOpenPaymentPopup}
        handleConfirm={handleConfirmPayment}
      />
      <InformationPopup
        openPopup={openInformationPopup}
        setOpenPopup={setOpenInformationPopup}
        currentData={currentData}
      />
    </>
  );
};

export default TableInterviews;
