import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { handleException } from '@/common/exceptions/exception-handling';
import { formatFullDate } from '@/common/helpers';
import * as toast from '@/common/helpers/toast';
import { ITableColumn } from '@/common/types';
import AppButton from '@/components/atom/Button/Button';
import { AppTable } from '@/components/atom/Table';
import Heading1 from '@/components/common/Heading1';
import ConfirmPopup from '@/components/modals/ConfirmPopup';
import { useAuth } from '@/contexts';
import { useLoading } from '@/hooks/useLoading';
import { AdminUsersApi } from '@/module/adminUsers/api';
import { IAdminUsersResponse } from '@/module/adminUsers/response/adminUsers.interface';

interface AdminUsersPageProps {}

const AdminUsersPage: React.FC<AdminUsersPageProps> = () => {
  const navigate = useNavigate();
  const [adminUsers, setAdminUsers] = useState<IAdminUsersResponse[]>([]);
  const { openLoading, closeLoading } = useLoading();
  const { user } = useAuth();

  const userDeletionPopupConfig = useMemo(
    () => ({
      title: '以下の管理者を削除しますか？',
      cancelText: 'キャンセル',
    }),
    [],
  );

  const columns: ITableColumn<IAdminUsersResponse>[] = [
    {
      label: '名前',
      headerClassName: 'w-[26%]',
      bodyClassName: 'w-[26%] truncate',
      valueGetter: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      field: 'email',
      label: 'メールアドレス',
      headerClassName: 'w-[26%]',
      bodyClassName: 'w-[26%] truncate',
    },
    {
      label: '登録日',
      field: 'createdAt',
      headerClassName: 'w-[19%]',
      bodyClassName: 'w-[19%]',
      valueGetter: (row) => formatFullDate(row.createdAt),
    },
    {
      label: '更新日',
      field: 'updatedAt',
      headerClassName: 'w-[19%]',
      bodyClassName: 'w-[19%]',
      valueGetter: (row) => formatFullDate(row.updatedAt),
    },
    {
      label: '編集',
      headerClassName: 'w-[15%]',
      bodyClassName: 'w-[15%]',
      valueGetter: (row) => <AppButton text="詳細" size="s-md" onClick={() => editAction(row.id)} />,
    },
    {
      label: '削除',
      headerClassName: 'w-[15%]',
      bodyClassName: 'w-[15%]',
      valueGetter: (row) =>
        row?.id !== user?.id && (
          <ConfirmPopup
            popupConfig={{
              ...userDeletionPopupConfig,
              description: `名前：${row.firstName} ${row.lastName}`,
              customeBtn: (
                <AppButton
                  onClick={() => deleteAction(row.id)}
                  text={'削除'}
                  size="sm"
                  custome="bg-red-450 px-0 flex-1"
                />
              ),
            }}
          >
            <AppButton
              text="削除"
              size="s-md"
              variant="outline"
              custome="border-red-450 text-red-450 lg:focus:text-red-450 lg:hover:text-red-450 lg:hover:bg-transparent lg:focus:bg-transparent"
            />
          </ConfirmPopup>
        ),
    },
  ];

  const fetchAdminUsers = async () => {
    try {
      openLoading();
      const response = await AdminUsersApi.getAllUsers();
      setAdminUsers(response.data);
    } catch (error: any) {
      handleException(error);
    } finally {
      closeLoading();
    }
  };

  const backAction = () => {
    navigate(`/home`);
  };

  const editAction = (adminUserId: string) => {
    navigate(`/admin-users/edit/${adminUserId || ''}`);
  };

  const deleteAction = async (adminUserId: string) => {
    try {
      openLoading();
      await AdminUsersApi.deleteUser(adminUserId);
      fetchAdminUsers();
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const registerAction = () => {
    navigate(`/admin-users/create`);
  };

  useEffect(() => {
    fetchAdminUsers();
  }, []);

  return (
    <div className="px-20 py-16">
      <Heading1 text="管理者一覧" backAction={backAction} />
      <div className="mb-14 mt-10">
        <div className="flex justify-end">
          <AppButton text="管理者登録" size="lg" onClick={registerAction} />
        </div>
      </div>
      <div className="">
        <AppTable columns={columns} rows={adminUsers} />
      </div>
    </div>
  );
};

export default AdminUsersPage;
