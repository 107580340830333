import { FC, useCallback } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import TextEditor from '@/components/TextEditor';
import AppFormField from '@/components/atom/AppFormField';
import AppButton from '@/components/atom/Button/Button';
import { useProjectLanguageMode } from '@/contexts';
import { EStatusProject } from '@/module/projectsList/constants';
import { IProjectForm } from '@/module/projectsList/project.interface';

export interface IStepTwoFormData {
  description: string;
}

interface StepTwoProps {
  onNextStep: (step: number) => void;
  onSaveDraft: () => void;
  errorMsg?: string;
  errors: FieldErrors<IProjectForm>;
  control: Control<IProjectForm>;
  status?: EStatusProject;
}

const StepTwo: FC<StepTwoProps> = ({ onNextStep, onSaveDraft, errors, control, status }) => {
  const { languageMode, isRootLanguageMode } = useProjectLanguageMode();

  const onSubmit = useCallback(() => {
    onNextStep(3);
  }, [onNextStep]);

  return (
    <div className="px-20 py-16">
      <form className="space-y-14">
        <div key={`content-${languageMode}`}>
          <AppFormField
            textLabel="本文 (30文字以上、1000文字以下)"
            required={isRootLanguageMode}
            errorText={errors.content?.message}
          >
            <Controller
              name="content"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextEditor
                  onEditorChange={onChange}
                  editorClassName="h-[600px] border-rounded"
                  value={value}
                  placeholder="本文を入力する"
                />
              )}
            />
          </AppFormField>
        </div>

        <div className="flex gap-3">
          {(status === EStatusProject.DRAFT || status === undefined) && (
            <AppButton type="button" text="下書きを保存" size="lg" variant="outline" onClick={onSaveDraft} />
          )}
          <AppButton type="button" text="次へ" size="lg" onClick={onSubmit} />
        </div>
      </form>
    </div>
  );
};

export default StepTwo;
