import * as yup from 'yup';

import { ERROR_MESSAGE, generateErrorLimitMsg, REGEX_ONLY_NUMBER } from '@/common/constants/common.constants';

export const STEP_FOUR_SCHEMA = yup.object().shape({
  companyName: yup.string().required(ERROR_MESSAGE.required),
  businessAddress: yup.string().required(ERROR_MESSAGE.required),
  necessaryExpenses: yup.string().required(ERROR_MESSAGE.required),
  otherInformation: yup.string().required(ERROR_MESSAGE.required).max(255, generateErrorLimitMsg(1, 255)),
  businessTel: yup
    .string()
    .required(ERROR_MESSAGE.required)
    .min(10, ERROR_MESSAGE.wrongFormat)
    .max(12, ERROR_MESSAGE.wrongFormat)
    .matches(REGEX_ONLY_NUMBER, ERROR_MESSAGE.wrongFormat),
});
