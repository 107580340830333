export enum EStatusProject {
  DRAFT = 1,
  CREATED = 2,
  PUBLISHED = 3,
  EDITING = 4,
}

export enum EProjectCategory {
  Movie = 1,
  Others = 2,
}

export const DEFAULT_DETAIL_DATA = {
  amountProgress: '',
  countUser: 0,
  countUserToday: 0,
  countUserYesterday: 0,
  currentApAmount: 0,
  currentApAmountExchangeRate: 0,
  remainingDays: 0,
  todayApAmount: 0,
  yesterdayApAmount: 0,
  id: '',
  title: '',
  thumbnailUrl: '',
  targetApAmount: '0',
  targetApAmountExchangeRate: 0,
  startDate: '',
  endDate: '',
  summary: '',
  category: 0,
  content: '',
  practitionerName: '',
  practitionerProfile: '',
  practitionerThumbnailUrl: '',
  xUrl: '',
  instagramUrl: '',
  status: 0,
  companyName: '',
  businessAddress: '',
  necessaryExpenses: '',
  otherInformation: '',
  businessTel: '',
  projectReturns: [],
  projectTranslations: [],
};
