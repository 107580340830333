import React, { createContext, ReactNode, useMemo, useState } from 'react';

import { AdminUserData, AuthContextData, IAdminUser } from '../interfaces/auth-context.interface';

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextData | undefined>(undefined);

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<IAdminUser | null>(null);

  const setUserLogin = (accessToken: string, refreshToken: string) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };

  // call this function when you want to authenticate the user
  const login = async (data: AdminUserData) => {
    setUserLogin(data.token, data.refreshToken);
  };

  // call this function to sign out logged in user
  const logout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  const getUserToken = () => {
    return localStorage.getItem('accessToken');
  };

  const setCurrentUser = (data: IAdminUser) => {
    setUser(data);
  };

  const value = useMemo(
    (): AuthContextData => ({
      user,
      login,
      logout,
      getUserToken,
      setCurrentUser,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
