import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { handleException } from '@/common/exceptions/exception-handling';
import { displayData, formatFullDate } from '@/common/helpers';
import * as toast from '@/common/helpers/toast';
import AppButton from '@/components/atom/Button/Button';
import Heading1 from '@/components/common/Heading1';
import ConfirmPopup from '@/components/modals/ConfirmPopup';
import { useFilter } from '@/contexts/FilterContext';
import { useLoading } from '@/hooks/useLoading';
import { UsersApi } from '@/module/users/api';
import { LabelDetail, LANGUAGE_CODE, SEX_CODE } from '@/module/users/constants';
import { IUsersBody } from '@/module/users/request/users.interface';
import { IUsersResponse } from '@/module/users/response/users.interface';
import RowDetail from '../components/RowDetail';

interface UserDetailPageProps {}

const popupConfig = {
  title: '以下のユーザーを削除しますか？',
  cancelText: 'キャンセル',
  confirmText: '削除',
};

const UserDetailPage: React.FC<UserDetailPageProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [userInfo, setUserInfo] = useState<IUsersResponse>({} as IUsersResponse);
  const { userId } = useParams() || '';
  const { openLoading, closeLoading } = useLoading();
  const { currentFilters, updateCurrentFilters } = useFilter<IUsersBody>();

  const fetchUserData = async (userId: string) => {
    try {
      openLoading();
      const response = await UsersApi.getUser(userId);
      const { data } = response;
      if (data) setUserInfo(data);
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const backAction = () => {
    const newParams = new URLSearchParams(searchParams);

    if (currentFilters?.search !== undefined) {
      newParams.set('search', currentFilters?.search);
    }
    if (currentFilters?.sortField !== undefined) {
      newParams.set('sortField', currentFilters?.sortField);
    }
    if (currentFilters?.sortOrder !== undefined) {
      newParams.set('sortOrder', currentFilters?.sortOrder);
    }

    navigate(`/users?${newParams.toString()}`);
    updateCurrentFilters(null);
  };

  const mapDataToDisplay = (data: IUsersResponse) => {
    return [
      {
        label: LabelDetail.NAME,
        value: `${data.nickName}`,
      },
      {
        label: LabelDetail.USER_NAME,
        value: `${data.userName}`,
      },
      {
        label: LabelDetail.DOB,
        value: `${formatFullDate(data.birthday)}`,
      },
      {
        label: LabelDetail.PHONE,
        value: `${displayData(data.tel)}`,
      },
      {
        label: LabelDetail.SEX,
        value: `${displayData(SEX_CODE[data.sex as keyof typeof SEX_CODE])}`,
      },
      {
        label: LabelDetail.LANGUAGE,
        value: `${displayData(LANGUAGE_CODE[data.language as keyof typeof LANGUAGE_CODE])}`,
      },
    ];
  };

  const deleteAction = async (adminUserId: string) => {
    try {
      openLoading();
      await UsersApi.deleteUser(adminUserId);
      backAction();
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    if (userId) fetchUserData(userId);
  }, []);

  return (
    <div className="px-20 py-16">
      <Heading1 text="ユーザー情報詳細" backAction={() => backAction()} />
      <div className="mt-10 py-4">
        <div className="flex flex-col gap-5">
          {mapDataToDisplay(userInfo).map((data, index) => (
            <div key={index}>
              <RowDetail data={data} key={index} />
            </div>
          ))}
        </div>
        <div className="mt-16 flex justify-center">
          <ConfirmPopup
            popupConfig={{
              ...popupConfig,
              description: `ユーザー名：${userInfo.userName}`,
              onConfirm: () => deleteAction(userInfo.id),
            }}
          >
            <AppButton text="削除" size="lg" custome="bg-red-450" />
          </ConfirmPopup>
        </div>
      </div>
    </div>
  );
};

export default UserDetailPage;
