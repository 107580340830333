import { ISelectData } from '@/components/atom/Select/Select';

export enum ENotificationCategory {
  NEWS = 1,
  MAINTENANCE = 2,
  SUPPORTING = 3,
}

export enum ENotificationTarget {
  ALL = 0,
  USER = 1,
  FLIMAKER = 2,
  ADMIN = 3,
}

export const NOTIFICATION_CATEGORY = {
  [ENotificationCategory.NEWS]: 'ニュース',
  [ENotificationCategory.MAINTENANCE]: 'メンテナンス',
  [ENotificationCategory.SUPPORTING]: 'サポート',
};

export const NOTIFICATION_CATEGORY_VALUES: ISelectData[] = [
  { value: ENotificationCategory.NEWS, label: 'ニュース' },
  { value: ENotificationCategory.MAINTENANCE, label: 'メンテナンス' },
  { value: ENotificationCategory.SUPPORTING, label: 'サポート' },
];
