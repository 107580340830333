import { parse } from 'date-fns';

export const setCurrentCaretPosition = (elemId: string, startCaretPos: number, endCaretPos: number) => {
  const input = document.getElementById(elemId) as HTMLInputElement;
  input?.focus();
  input?.setSelectionRange(startCaretPos, endCaretPos);
};

export const formatFullDate = (date: string) => {
  if (!date) return '';

  return new Date(date).toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const formatFullDateTime = (date: string) => {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    hour12: false,
  };
  const formattedDate = new Date(date).toLocaleDateString('ja-JP', options).replace('/', '月').replace(' ', '日');
  return `${formattedDate}予定`;
};

export const toEndOfDayUTC = (date: Date): Date => {
  // Set the time to the end of the day in the local timezone
  date.setHours(23, 59, 59, 0);

  // Get the UTC equivalent
  const endOfDayUTC = new Date(date);

  return endOfDayUTC;
};

export const toStartOfDayUTC = (date: Date): Date => {
  // Set the time to the end of the day in the local timezone
  date.setHours(0, 0, 0, 0);

  // Get the UTC equivalent
  const startOfDayUTC = new Date(date);

  return startOfDayUTC;
};

// export const generateErrorMsg = (errorCode: keyof typeof BE_MESSAGE | null) => {
//   if (!errorCode) return "";
//   return BE_MESSAGE[errorCode] ? BE_MESSAGE[errorCode] : BE_MESSAGE.AMCMM0001;
// };

export const getFullName = (firstName: string, lastName: string) => {
  return [firstName, lastName].filter(Boolean).join(' ');
};

export const displayData = (data: string | number) => {
  return data ? data : '無し';
};

export const parseDateString = (originalValue: any, dateFormat: string) => {
  const parsedDate = parse(originalValue, dateFormat, new Date());
  return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
};

export function formatNumberToJapanese(num: number) {
  if (!num) return 0;

  if (num >= 10000) {
    return `${(num / 10000).toLocaleString('en-US')}万`;
  } else if (num >= 100000000) {
    return `${(num / 100000000).toLocaleString('en-US')}億`;
  } else {
    return num.toString();
  }
}
export const removeHtmlTags = (value: string | undefined) => {
  if (!value) return ''; // empty value is considered empty

  // Remove all HTML tags and trim the resulting string
  const strippedString = value.replace(/<[^>]*>/g, '').trim();

  // Check if the stripped string is empty
  return strippedString;
};

export const cleanObject = <T extends object>(obj: T): T => {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
        delete obj[key as keyof T]; // Type assertion since TypeScript cannot guarantee key exists after deletion
      }
    }
  }
  return obj;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  // Remove all non-digit characters for a clean start
  if (!phoneNumber) return '';
  const digits = phoneNumber.replace(/\D/g, '');

  if (digits.length === 10) {
    // Format: XXX-XXXX-XXX
    return digits.replace(/(\d{3})(\d{4})(\d{3})/, '$1-$2-$3');
  } else if (digits.length === 11) {
    // Format: XXX-XXXX-XXXX
    return digits.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (digits.length === 12) {
    // Format: XXXX-XXXX-XXXX
    return digits.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
  } else {
    // Return the original string if it doesn't match any expected length
    return phoneNumber;
  }
};

export const formatToLocaleString = (value: number, mode: string = 'en-US') => {
  if (!value) return 0;

  return value.toLocaleString(mode);
};

export const getImageMimeType = (filename: string) => {
  const ext = filename.split('.').pop();
  if (!ext) return 'application/octet-stream';
  let mimeType = '';
  switch (ext.toLocaleLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'jfif':
      mimeType = 'image/jpeg';
      break;
    case 'gif':
      mimeType = 'image/gif';
      break;
    case 'png':
      mimeType = 'image/png';
      break;
    case 'svg':
      mimeType = 'image/svg+xml';
      break;
    default:
      mimeType = 'application/octet-stream';
  }

  return mimeType;
};
