import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './App.css';
import ProtectedRoute from './components/atom/ProtectedRoute/ProtectedRoot';
import { AuthProvider, ProjectLanguageModeProvider } from './contexts';
import { AffiliateProvider } from './contexts/AffiliateContext';
import { FilterProvider } from './contexts/FilterContext';
import { IssueApProvider } from './contexts/IssueApContext';
import { LoadingProvider } from './contexts/LoadingContext';
import { SendReturnProvider } from './contexts/SendReturnContext';
import LoginLayout from './layouts/AuthLayout/AuthLayout';
import DetailLayout from './layouts/DetailLayout/DetailLayout';
import RootLayout from './layouts/RootLayout/RootLayout';
import { AdminUsersPage, EditPage, RegisterPage } from './pages/AdminUsers';
import { AffiliateUserSelectionPage, CreateAffiliatePage, CreateAffiliateSuccessPage } from './pages/Affiliate';
import BasicAuthen from './pages/Auth/BasicAuthen';
import LoginPage from './pages/Auth/LoginPage';
import TwoFAVerification from './pages/Auth/TwoFAVerification';
import InterviewsPage from './pages/Interviews';
import { IssueApForm, IssueApUserSelectionPage } from './pages/IssueAp';
import { CreateNotificationPage, EditNotificationPage, NotificationsPage } from './pages/NotificationsPage';
import ProjectsListPage from './pages/ProjectsList';
import CreateProjectPage from './pages/ProjectsList/CreateProjectPage/CreateProjectPage';
import EditProjectPage from './pages/ProjectsList/EditProjectPage/EditProjectPage';
import PreviewPage from './pages/ProjectsList/PreviewPage/PreviewPage';
import ProjectDetailPage from './pages/ProjectsList/ProjectDetailPage';
import { SendReturnForm, SendReturnUserSelectionPage } from './pages/SendReturn';
import SupportersListPage from './pages/SupportersListPage/SupportersListPage';
import UsersPage from './pages/Users';
import UserDetailPage from './pages/Users/UserDetailPage';

const Root: React.FC = () => {
  return <Outlet />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/auth',
        element: <LoginLayout />,
        children: [
          {
            path: '/auth/basic-authencation',
            element: <BasicAuthen />,
          },
          {
            path: '/auth/login',
            element: <LoginPage />,
          },
          {
            path: '/auth/2fa-verification',
            element: <TwoFAVerification />,
          },
        ],
      },
      {
        path: '/',
        element: <RootLayout />,
        children: [
          {
            path: '/',
            element: (
              <ProtectedRoute>
                <ProjectsListPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/home',
            element: (
              <ProtectedRoute>
                <ProjectsListPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/users',
            element: (
              <ProtectedRoute>
                <UsersPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/users/preview/:userId',
            element: (
              <ProtectedRoute>
                <UserDetailPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/admin-users',
            element: (
              <ProtectedRoute>
                <AdminUsersPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/admin-users/create',
            element: (
              <ProtectedRoute>
                <RegisterPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/admin-users/edit/:adminUserId',
            element: (
              <ProtectedRoute>
                <EditPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/projects',
            element: (
              <ProtectedRoute>
                <ProjectsListPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/notifications',
            element: (
              <ProtectedRoute>
                <NotificationsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/notifications/create',
            element: (
              <ProtectedRoute>
                <CreateNotificationPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/notifications/edit/:notiId',
            element: (
              <ProtectedRoute>
                <EditNotificationPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/projects',
            element: (
              <ProtectedRoute>
                <ProjectsListPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/projects/create',
            element: (
              <ProtectedRoute>
                <ProjectLanguageModeProvider>
                  <CreateProjectPage />
                </ProjectLanguageModeProvider>
              </ProtectedRoute>
            ),
          },
          {
            path: '/projects/:projectId/draft',
            element: (
              <ProtectedRoute>
                <ProjectLanguageModeProvider>
                  <EditProjectPage />
                </ProjectLanguageModeProvider>
              </ProtectedRoute>
            ),
          },
          {
            element: (
              <ProtectedRoute>
                <IssueApProvider>
                  <Outlet />
                </IssueApProvider>
              </ProtectedRoute>
            ),
            children: [
              {
                path: '/issue-ap',
                element: <IssueApUserSelectionPage />,
              },
              {
                path: '/issue-ap/form',
                element: <IssueApForm />,
              },
            ],
          },
          {
            element: (
              <ProtectedRoute>
                <AffiliateProvider>
                  <Outlet />
                </AffiliateProvider>
              </ProtectedRoute>
            ),
            children: [
              {
                path: '/affiliate',
                element: <AffiliateUserSelectionPage />,
              },
              {
                path: '/affiliate/create/form',
                element: <CreateAffiliatePage />,
              },
              {
                path: '/affiliate/create/success',
                element: <CreateAffiliateSuccessPage />,
              },
            ],
          },
        ],
      },
      {
        path: '/projects',
        element: <DetailLayout />,
        children: [
          {
            path: '/projects/:projectId/dashboard',
            element: (
              <ProtectedRoute>
                <ProjectDetailPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/projects/:projectId/preview',
            element: (
              <ProtectedRoute>
                <PreviewPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/projects/:projectId/edit',
            element: (
              <ProtectedRoute>
                <ProjectLanguageModeProvider>
                  <EditProjectPage />
                </ProjectLanguageModeProvider>
              </ProtectedRoute>
            ),
          },
          {
            path: '/projects/:projectId/interviews',
            element: (
              <ProtectedRoute>
                <InterviewsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: '/projects/:projectId/supporters',
            element: (
              <ProtectedRoute>
                <SupportersListPage />
              </ProtectedRoute>
            ),
          },
          {
            element: (
              <ProtectedRoute>
                <SendReturnProvider>
                  <Outlet />
                </SendReturnProvider>
              </ProtectedRoute>
            ),
            children: [
              {
                path: '/projects/:projectId/send-return',
                element: <SendReturnUserSelectionPage />,
              },
              {
                path: '/projects/:projectId/send-return/form',
                element: <SendReturnForm />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

const App: React.FC = () => {
  return (
    <LoadingProvider>
      <AuthProvider>
        <FilterProvider>
          <ToastContainer position="top-right" hideProgressBar={true} autoClose={3000} closeButton={false} />
          <RouterProvider router={router} />
        </FilterProvider>
      </AuthProvider>
    </LoadingProvider>
  );
};

export default App;
