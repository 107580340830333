import * as yup from 'yup';

import { ERROR_MESSAGE } from '@/common/constants/common.constants';

export const STEP_THREE_SCHEMA = yup.object().shape({
  id: yup.string().required(ERROR_MESSAGE.required),
  returnName: yup.string().required(ERROR_MESSAGE.required),
  thumbnailUrl: yup.mixed().required(ERROR_MESSAGE.required),
  isNft: yup.boolean().required(ERROR_MESSAGE.required).default(false),
  isQuantitySelectable: yup.boolean().required(ERROR_MESSAGE.required).default(false),
  isInterviewRequired: yup.boolean().required(ERROR_MESSAGE.required).default(false),
  apAmount: yup
    .string()
    .typeError(ERROR_MESSAGE.wrongFormat)
    .test('min', ERROR_MESSAGE.wrongFormat, (value) => {
      const num = Number(value);
      return num >= 0;
    })
    .required(ERROR_MESSAGE.required),
  quantity: yup
    .number()
    .typeError(ERROR_MESSAGE.wrongFormat)
    .integer(ERROR_MESSAGE.wrongFormat)
    .min(1, ERROR_MESSAGE.wrongFormat)
    .required(ERROR_MESSAGE.required),
  content: yup.string().required(ERROR_MESSAGE.required),
});

export const STEP_THREE_LANGUAGE_SCHEMA = yup.object().shape({
  id: yup.string().required(ERROR_MESSAGE.required),
  returnName: yup.string().default(''),
  thumbnailUrl: yup.mixed().required(ERROR_MESSAGE.required),
  isNft: yup.boolean().required(ERROR_MESSAGE.required).default(false),
  isQuantitySelectable: yup.boolean().required(ERROR_MESSAGE.required).default(false),
  isInterviewRequired: yup.boolean().required(ERROR_MESSAGE.required).default(false),
  apAmount: yup
    .string()
    .typeError(ERROR_MESSAGE.wrongFormat)
    .test('min', ERROR_MESSAGE.wrongFormat, (value) => {
      const num = Number(value);
      return num >= 0;
    })
    .required(ERROR_MESSAGE.required),
  quantity: yup
    .number()
    .typeError(ERROR_MESSAGE.wrongFormat)
    .integer(ERROR_MESSAGE.wrongFormat)
    .min(1, ERROR_MESSAGE.wrongFormat)
    .required(ERROR_MESSAGE.required),
  content: yup.string().default(''),
});
