import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { handleException } from '@/common/exceptions/exception-handling';
import * as toast from '@/common/helpers/toast';
import AppFormField from '@/components/atom/AppFormField';
import AppButton from '@/components/atom/Button/Button';
import TextField from '@/components/atom/TextField/TextField';
import Heading1 from '@/components/common/Heading1';
import { useAffiliate } from '@/contexts/AffiliateContext';
import { useFilter } from '@/contexts';
import { useLoading } from '@/hooks/useLoading';
import { CREATE_AFFILIATE_SCHEMA } from '@/module/affiliates/affiliate.validator';
import { AffiliateApi } from '@/module/affiliates/api';
import { CreateAffiliatePayload } from '@/module/affiliates/type.interface';
import { IUsersBody } from '@/module/users/request/users.interface';
import { yupResolver } from '@hookform/resolvers/yup';

export const CreateAffiliatePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { openLoading, closeLoading } = useLoading();
  const { checkedUserId, setAffiliateLink } = useAffiliate();
  const { currentFilters, updateCurrentFilters } = useFilter<IUsersBody>();

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateAffiliatePayload>({
    resolver: yupResolver(CREATE_AFFILIATE_SCHEMA),
  });

  const handleBack = () => {
    const newParams = new URLSearchParams(searchParams);

    if (currentFilters?.search !== undefined) {
      newParams.set('search', currentFilters?.search);
    }
    if (currentFilters?.sortField !== undefined) {
      newParams.set('sortField', currentFilters?.sortField);
    }
    if (currentFilters?.sortOrder !== undefined) {
      newParams.set('sortOrder', currentFilters?.sortOrder);
    }

    navigate(`/affiliate?${newParams.toString()}`);
    updateCurrentFilters(null);
  };

  const handleCommissionRateChange = (value: string) => {
    const numericValue = Number(value);
    setValue('commissionRate', numericValue);
    trigger('commissionRate');
  };

  const handleCreateAffiliate = async (fields: CreateAffiliatePayload) => {
    try {
      openLoading();
      const payload = { ...fields, userId: checkedUserId };
      const { data } = await AffiliateApi.create(payload);
      setAffiliateLink(data.affiliateLink);
      navigate('/affiliate/create/success');
    } catch (err) {
      const errorResult = handleException(err);
      toast.showError(errorResult.errorMessage ?? errorResult.errorMessages);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    if (!checkedUserId) {
      return handleBack();
    }
  }, [checkedUserId]);

  return (
    <div className="space-y-8 px-20 py-16">
      <Heading1 text="アフィリエイト - 分配率" backAction={handleBack} />
      <form className="flex flex-col items-center space-y-8" onSubmit={handleSubmit(handleCreateAffiliate)}>
        <div className="flex w-full flex-col">
          <AppFormField
            textLabel="分配率"
            required={true}
            errorText={errors.commissionRate?.message}
            hint={errors.commissionRate ? '' : '招待されたユーザーが支援した金額のうち、招待者に分配する比率'}
          >
            <Controller
              name="commissionRate"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  placeholder="3%"
                  error={errors.commissionRate}
                  className="[&>input]:px-5 [&>input]:text-right [&>input]:text-base"
                  {...field}
                  value={field.value || ''}
                  onChange={(e) => handleCommissionRateChange(e.target.value)}
                />
              )}
            />
          </AppFormField>
        </div>
        <AppButton text="リンク発行" size="lg" />
      </form>
    </div>
  );
};
