import { format, Locale } from 'date-fns';
import ja from 'date-fns/locale/ja';
import React from 'react';
import { default as DatePicker, default as ReactDatePicker, registerLocale } from 'react-datepicker';
import { FieldError } from 'react-hook-form';

import { FULL_DATE_TIME_FORMAT } from '@/common/constants/common.constants';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ja', ja as unknown as Locale);

export interface ISelectData {
  leftIcon?: React.ReactNode;
  label: string;
  value: string | number;
  rightIcon?: React.ReactNode;
}

interface AppDatePickerProps {
  isShowCalendarIcon?: boolean;
  value: Date;
  minDate?: Date | null;
  maxDate?: Date | null;
  placeholder?: string;
  dateFormat?: string;
  error?: FieldError | undefined;
  showTimeInput?: boolean;
  popperPlacement?: string;
  disabled?: boolean;
  onChange: (...event: any[]) => void;
}

const AppDatePicker = React.forwardRef<ReactDatePicker<undefined, undefined>, AppDatePickerProps>(
  (
    {
      value,
      onChange,
      minDate,
      maxDate,
      isShowCalendarIcon = true,
      placeholder,
      error,
      dateFormat = FULL_DATE_TIME_FORMAT,
      popperPlacement = 'bottom-start',
      showTimeInput = false,
      disabled = false,
    },
    ref,
  ) => {
    const parseDate = (originalValue: Date) => {
      return value instanceof Date && !isNaN(originalValue.getTime()) ? format(originalValue, dateFormat) : '';
    };

    const CustomInput = React.forwardRef<HTMLInputElement, { value?: string; onClick?: () => void }>(
      ({ value, onClick }, ref) => (
        <div
          onClick={onClick}
          className={`border-border-input flex h-[40px] w-full gap-2 rounded-[6px] p-2 text-[14px] text-[#1c2024] outline-none ${error ? 'no-outline-important border-2 border-primary-500' : 'border'}`}
        >
          {isShowCalendarIcon && <img src="/assets/images/calendar-icon.svg" alt="calendar-icon" />}
          <input
            placeholder={placeholder}
            value={value}
            ref={ref}
            readOnly
            className="w-full bg-transparent focus-visible:outline-none"
          />
        </div>
      ),
    );

    const CustomTimeInput = ({ value, onChange }: { value?: string; onChange: (value: string) => void }) => (
      <input
        value={value}
        disabled={!value}
        placeholder="HH:mm"
        onChange={(e) => onChange(e.target.value)}
        className="p-1"
        style={{ border: 'solid 1px' }}
      />
    );

    return (
      <DatePicker
        ref={ref}
        onChange={(date: Date) => onChange(date)}
        minDate={minDate}
        showTimeInput={showTimeInput}
        customTimeInput={<CustomTimeInput value={parseDate(value)} onChange={onChange} />}
        disabled={disabled}
        maxDate={maxDate}
        wrapperClassName="w-full focus:border-[#8da4ef] focus:border"
        dateFormat={dateFormat}
        selected={value instanceof Date && !isNaN(value.getTime()) ? value : null}
        locale="ja"
        popperPlacement={popperPlacement as any}
        customInput={<CustomInput value={parseDate(value)} />}
      />
    );
  },
);

export default AppDatePicker;
