import { LANGUAGE_LABELS } from '@/common/constants';
import { ELanguage } from '@/common/enums';
import { useProjectLanguageMode } from '@/contexts';
import { cn } from '@/lib/utils';

interface LanguageBarProps {
  disabled?: boolean;
}

export const LanguageBar = ({ disabled = false }: LanguageBarProps) => {
  const { languageMode, setLanguageMode } = useProjectLanguageMode();

  return (
    <div className="flex justify-center">
      {Object.values(ELanguage).map((lang) => (
        <button
          key={lang}
          onClick={() => setLanguageMode(lang)}
          disabled={disabled}
          className={cn(
            'transaction border border-b-2 border-transparent border-b-slate-200 text-sm font-medium text-[#5D5D5B] duration-200',
            languageMode === lang && 'border-b-primary-500 text-primary-500',
            disabled && 'cursor-not-allowed opacity-50',
          )}
        >
          <div className="p-2">
            <p>{LANGUAGE_LABELS[lang]}</p>
          </div>
        </button>
      ))}
    </div>
  );
};
