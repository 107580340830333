import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FULL_DATE_TIME_FORMAT } from '@/common/constants/common.constants';
import { handleException } from '@/common/exceptions/exception-handling';
import { displayData } from '@/common/helpers';
import * as toast from '@/common/helpers/toast';
import { ITableColumn } from '@/common/types';
import AppButton from '@/components/atom/Button/Button';
import { AppTable } from '@/components/atom/Table';
import Heading1 from '@/components/common/Heading1';
import ConfirmPopup from '@/components/modals/ConfirmPopup';
import { useAuth } from '@/contexts';
import { useLoading } from '@/hooks/useLoading';
import { NotificationsApi } from '@/module/notifications/api';
import { NOTIFICATION_CATEGORY } from '@/module/notifications/constants';
import { INotificationsResponse } from '@/module/notifications/response/notifications.interface';

interface NotificationsPageProps {}

const NotificationsPage: React.FC<NotificationsPageProps> = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<INotificationsResponse[]>([]);
  const { openLoading, closeLoading } = useLoading();
  const { user } = useAuth();

  const notificationDeletionPopupConfig = useMemo(
    () => ({
      title: '通知の削除',
      children: (
        <div className="mb-6 mt-4 text-base font-medium text-secondary-500">
          <p>こちらの通知を削除してよろしいですか？この操作は取り消しできません</p>
        </div>
      ),
      cancelText: 'キャンセル',
      confirmText: '削除',
    }),
    [],
  );

  const columns: ITableColumn<INotificationsResponse>[] = [
    {
      field: 'category',
      label: 'カテゴリ',
      headerClassName: 'w-[26%]',
      bodyClassName: 'w-[26%] truncate',
      valueGetter: (row) => NOTIFICATION_CATEGORY[row.category as keyof typeof NOTIFICATION_CATEGORY],
    },
    {
      field: 'title',
      label: 'タイトル',
      headerClassName: 'w-[26%]',
      bodyClassName: 'w-[26%] truncate',
      valueGetter: (row) => displayData(row.title),
    },
    {
      field: 'startDate',
      label: '開始日時',
      headerClassName: 'w-[19%]',
      bodyClassName: 'w-[19%]',
      valueGetter: (row) => displayData(format(row.startDate, FULL_DATE_TIME_FORMAT)),
    },
    {
      field: 'endDate',
      label: '終了日時',
      headerClassName: 'w-[19%]',
      bodyClassName: 'w-[19%]',
      valueGetter: (row) => displayData(format(row.endDate, FULL_DATE_TIME_FORMAT)),
    },
    {
      label: '編集',
      headerClassName: 'w-[15%]',
      bodyClassName: 'w-[15%]',
      valueGetter: (row) => <AppButton text="編集" size="s-md" onClick={() => editAction(row.id)} />,
    },
    {
      label: '削除',
      headerClassName: 'w-[15%]',
      bodyClassName: 'w-[15%]',
      valueGetter: (row) =>
        row?.id !== user?.id && (
          <ConfirmPopup
            popupConfig={{
              ...notificationDeletionPopupConfig,
              onConfirm: () => deleteAction(row.id),
            }}
          >
            <AppButton
              text="削除"
              size="s-md"
              variant="outline"
              custome="border-red-450 text-red-450 lg:focus:text-red-450 lg:hover:text-red-450 lg:hover:bg-transparent lg:focus:bg-transparent"
            />
          </ConfirmPopup>
        ),
    },
  ];

  const fetchNotifications = async () => {
    try {
      openLoading();
      const response = await NotificationsApi.getAllNotifications();
      if (response?.data) {
        setNotifications(response?.data);
      }
    } catch (error: any) {
      handleException(error);
    } finally {
      closeLoading();
    }
  };

  const backAction = () => {
    navigate(`/home`);
  };

  const editAction = (notiId: string) => {
    navigate(`/notifications/edit/${notiId || ''}`);
  };

  const deleteAction = async (notiId: string) => {
    try {
      openLoading();
      await NotificationsApi.deleteNotification(notiId);
      fetchNotifications();
    } catch (error: any) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
    } finally {
      closeLoading();
    }
  };

  const createAction = () => {
    navigate(`/notifications/create`);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="px-20 py-16">
      <Heading1 text="通知一覧画面" backAction={backAction} />
      <div className="mb-14 mt-10">
        <div className="flex justify-end">
          <AppButton text="新規登録" size="lg" onClick={createAction} />
        </div>
      </div>
      <div className="">
        <AppTable columns={columns} rows={notifications} />
      </div>
    </div>
  );
};

export default NotificationsPage;
