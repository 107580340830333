import axios from '@/api/axios';
import { IResponseResult } from '@/interfaces/common.interface';
import { IProjectDetailResponseData, IProjectsListResponse } from './response/projectsList.interface';
import { IProject, IProjectForm } from './project.interface';

export class ProjectsListApi {
  static getAllProjects(): Promise<IResponseResult<IProjectsListResponse[]>> {
    return axios.get('projects').then((res) => res.data);
  }

  static getProject(projectId: string): Promise<IResponseResult<IProjectDetailResponseData>> {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    return axios
      .get(`projects/${projectId}?yesterday=${yesterday.toISOString()}&today=${today.toISOString()}`)
      .then((res) => res.data);
  }

  static createProject(body: Partial<IProject>) {
    return axios.post('projects', body);
  }

  static editProject(projectId: string, body?: Partial<IProject>) {
    return axios.put(`projects/${projectId}`, body);
  }

  static publishProject(projectId: string) {
    return axios.post(`projects/publish/${projectId}`);
  }

  static deleteProject(projectId: string) {
    return axios.delete(`projects/${projectId}`);
  }

  static saveDraftProject(body: Partial<IProjectForm>) {
    return axios.post('projects/draft', body);
  }
}
