import { DndContext, DragEndEvent, closestCorners } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { FC, useCallback, useMemo } from 'react';

import { ELanguage } from '@/common/enums';
import AppButton from '@/components/atom/Button/Button';
import Heading1 from '@/components/common/Heading1';
import { useProjectLanguageMode } from '@/contexts';
import { EStatusProject } from '@/module/projectsList/constants';
import { IProjectForm, IStepThreeFormItem } from '@/module/projectsList/project.interface';
import { SortableItem } from './SortableItem';

interface IListScreenProps {
  onCreateButtonClick: () => void;
  onNextStep: () => void;
  onItemClick: (id: string) => void;
  onSaveDraft: () => void;
  data?: IStepThreeFormItem[];
  status?: EStatusProject;
  allFormValues: Record<ELanguage, IProjectForm>;
  setAllFormValues: (values: Partial<Record<ELanguage, Partial<IProjectForm>>>) => void;
}

const ListScreen: FC<IListScreenProps> = ({
  onCreateButtonClick,
  onNextStep,
  onItemClick,
  onSaveDraft,
  data,
  status,
  allFormValues,
  setAllFormValues,
}) => {
  const { isRootLanguageMode, languageMode } = useProjectLanguageMode();

  const sortedData = useMemo(() => {
    return [...(data ?? [])].sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0));
  }, [data]);

  const getUpdatedReturns = (language: ELanguage, updatedData: IStepThreeFormItem[]): IStepThreeFormItem[] => {
    if (languageMode === language) return updatedData;
    const currentReturns = allFormValues[language]?.projectReturns || [];
    if (currentReturns.length === 0) return [];
    return currentReturns.map((item) => {
      const matchingItem = updatedData.find((updatedItem) => updatedItem.id === item.id);
      return matchingItem ? { ...item, sortIndex: matchingItem.sortIndex } : item;
    });
  };

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;
    if (!over || active.id === over.id || !sortedData) return;
    const oldIndex = sortedData.findIndex((item) => item.id === active.id);
    const newIndex = sortedData.findIndex((item) => item.id === over.id);
    if (oldIndex === -1 || newIndex === -1) {
      console.error('Invalid drag indices:', { oldIndex, newIndex, activeId: active.id, overId: over.id });
      return;
    }
    const updatedItems = arrayMove(sortedData, oldIndex, newIndex).map((item, index) => ({
      ...item,
      sortIndex: index,
    }));

    // Update form values for both languages
    setAllFormValues({
      [ELanguage.Japanese]: {
        ...allFormValues[ELanguage.Japanese],
        projectReturns: getUpdatedReturns(ELanguage.Japanese, updatedItems),
      },
      [ELanguage.English]: {
        ...allFormValues[ELanguage.English],
        projectReturns: getUpdatedReturns(ELanguage.English, updatedItems),
      },
    });
  };

  const handleNextStep = useCallback(() => {
    onNextStep();
  }, [onNextStep]);

  return (
    <div className="flex flex-col items-center space-y-14 py-16 lg:block">
      <div className="flex w-full justify-center lg:justify-end">
        <AppButton text="リターン追加" size="lg" disabled={!isRootLanguageMode} onClick={onCreateButtonClick} />
      </div>

      {sortedData && sortedData.length > 0 ? (
        <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
          <SortableContext items={sortedData} strategy={rectSortingStrategy}>
            <div className="grid w-full grid-cols-2 justify-items-stretch gap-x-10 gap-y-10 lg:grid-cols-3 2xl:grid-cols-4">
              {sortedData.map((item) => (
                <SortableItem key={item.id} item={item} onClick={() => onItemClick(item.id)} />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      ) : (
        <div className="flex justify-center">
          <Heading1 text="リターン無し" />
        </div>
      )}

      <div className="mt-[10rem] flex gap-3">
        {status === EStatusProject.DRAFT ||
          (status === undefined && (
            <AppButton type="button" text="下書きを保存" size="lg" variant="outline" onClick={onSaveDraft} />
          ))}
        <AppButton type="button" text="次へ" size="lg" onClick={handleNextStep} />
      </div>
    </div>
  );
};

export default ListScreen;
