import { TextField as Text } from '@radix-ui/themes';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { FULL_DATE_TIME_FORMAT } from '@/common/constants/common.constants';
import { SortOrderEnum } from '@/common/enums';
import { handleException } from '@/common/exceptions/exception-handling';
import { displayData } from '@/common/helpers';
import * as toast from '@/common/helpers/toast';
import { ITableColumn } from '@/common/types';
import AppButton from '@/components/atom/Button/Button';
import { AppTable } from '@/components/atom/Table';
import TextField from '@/components/atom/TextField/TextField';
import Heading1 from '@/components/common/Heading1';
import { useLoading } from '@/hooks/useLoading';
import { SupportersApi } from '@/module/supportersList/api';
import { ISupporterParams } from '@/module/supportersList/request/supportersList.interface';
import { ISupporterResponse } from '@/module/supportersList/response/supportersList.interface';

interface SupportersListPageProps {}

const SupportersListPage: React.FC<SupportersListPageProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentSearch = searchParams.get('search') || '';
  const currentSortField = (searchParams.get('sortField') as keyof ISupporterResponse) || 'supportDate';
  const currentSortOrder = (searchParams.get('sortOrder') as SortOrderEnum) || SortOrderEnum.Desc;
  const [supporters, setSupporters] = useState<ISupporterResponse[]>([]);
  const [searchTerm, setSearchTerm] = useState(currentSearch);
  const [sortField, setSortField] = useState<keyof ISupporterResponse>(currentSortField);
  const [sortOrder, setSortOrder] = useState<SortOrderEnum>(currentSortOrder);
  const { projectId } = useParams() || '';
  const { openLoading, closeLoading } = useLoading();

  const debouncedSearch = useRef(
    debounce(async (term: string) => {
      await fetchSupporters({ search: term });
    }, 500),
  ).current;

  const columns: ITableColumn<ISupporterResponse>[] = [
    {
      field: 'returnName',
      label: 'リターン名',
      headerClassName: 'w-[25%]',
      bodyClassName: 'w-[25%] truncate',
      sortable: true,
      valueGetter: (row) => displayData(row.returnName),
    },
    {
      field: 'userEmail',
      label: 'メールアドレス',
      headerClassName: 'w-[25%]',
      bodyClassName: 'w-[25%] truncate',
      sortable: true,
      valueGetter: (row) => displayData(row.userEmail),
    },
    {
      field: 'userNickName',
      label: '支援者名',
      headerClassName: 'w-[12%]',
      bodyClassName: 'w-[12%]',
      sortable: true,
      valueGetter: (row) => displayData(row.userNickName),
    },
    {
      field: 'supportDate',
      label: '支援日',
      headerClassName: 'w-[10%]',
      bodyClassName: 'w-[10%]',
      sortable: true,
      valueGetter: (row) => displayData(format(row.supportDate, FULL_DATE_TIME_FORMAT)),
    },
    {
      field: 'apAmount',
      label: '支援金額',
      headerClassName: 'w-[28%] [&>span]:justify-center',
      bodyClassName: 'w-[28%] text-center',
      sortable: true,
      valueGetter: (row) => displayData(`${row.apAmount} AP`),
    },
  ];

  const updateUrlParams = (params: ISupporterParams) => {
    const newParams = new URLSearchParams(searchParams);

    if (params.search !== undefined) {
      newParams.set('search', params.search);
    }
    if (params.sortField !== undefined) {
      newParams.set('sortField', params.sortField);
    }
    if (params.sortOrder !== undefined) {
      newParams.set('sortOrder', params.sortOrder);
    }

    navigate(`/projects/${projectId}/supporters?${newParams.toString()}`);
  };

  const fetchSupporters = async (params?: ISupporterParams) => {
    try {
      openLoading();
      const response = await SupportersApi.getAllSupporters(projectId || '', params);
      setSupporters(response.data);
    } catch (error) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
      return [];
    } finally {
      closeLoading();
    }
  };

  const handleSort = (newSortField: keyof ISupporterResponse, newSortOrder: SortOrderEnum) => {
    setSortField(newSortField);
    setSortOrder(newSortOrder);

    const params = {
      search: searchTerm,
      sortField: newSortField,
      sortOrder: newSortOrder,
    };

    updateUrlParams(params);
    fetchSupporters(params);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    updateUrlParams({ search: newSearchTerm });
    debouncedSearch(newSearchTerm);
  };

  const handleBack = () => {
    navigate(`/projects/${projectId}/dashboard`);
  };

  const handleExportCSV = async () => {
    if (!projectId) return;
    try {
      openLoading();
      await SupportersApi.exportCsv(projectId);
      toast.showSuccess('CSV 出力が完了しました');
    } catch (error) {
      handleException(error);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    fetchSupporters({ search: searchTerm });
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <div className="px-20 py-16">
      <Heading1 text="支援者一覧" backAction={handleBack} />
      <div className="my-10">
        <div className="my-10 flex w-full gap-10">
          <div className="flex-1">
            <TextField placeholder="メールアドレス検索" value={searchTerm} onChange={(value) => onSearch(value)}>
              <Text.Slot></Text.Slot>
              <Text.Slot>
                <img src="/assets/images/search-icon.svg" alt="" />
              </Text.Slot>
            </TextField>
          </div>
          <AppButton text="CSV 出力" size="s-md" onClick={handleExportCSV} />
        </div>
      </div>
      <div className="">
        <AppTable columns={columns} rows={supporters} sortField={sortField} sortOrder={sortOrder} onSort={handleSort} />
      </div>
    </div>
  );
};

export default SupportersListPage;
