export enum LabelDetail {
  NAME = 'ニックネーム',
  EMAIL = 'email',
  DOB = '生年月日',
  PHONE = '電話番号',
  USER_NAME = 'ユーザー名',
  SEX = '性別',
  LANGUAGE = '言語',
}

export const SEX_CODE = {
  0: '男性',
  1: '女性',
  2: 'その他',
};

export const LANGUAGE_CODE = {
  ja: '日本語',
  en: '英語',
};
