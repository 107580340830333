import React, { useEffect, useState } from "react";
import "./SideBar.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getFullName } from "@/common/helpers";
import { useAuth } from "@/contexts";
import { ITabs, TabType } from "@/common/constants/user-page.constants";
import { UserApi } from "@/module/user/api";
import { handleException } from "@/common/exceptions/exception-handling";
interface ISideBarProps {
  tabs: ITabs[];
  mode?: string;
}
const SideBar: React.FC<ISideBarProps> = ({ tabs, mode = "root" }) => {
  const [userName, setUserName] = useState("");
  const { logout, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const { projectId } = useParams() || "";

  const handleLogout = () => {
    logout();
    navigate("/auth/basic-authencation");
  };

  useEffect(() => {
    const getCurrentAdmin = async () => {
      try {
        const response = await UserApi.getCurrentAdmin();
        const userFullName = getFullName(
          response.data?.firstName,
          response.data?.lastName
        );
        setCurrentUser(response.data);
        setUserName(userFullName);
      } catch (error) {
        handleException(error);
      }
    };
    getCurrentAdmin();
  }, []);

  const generateBgStyle = () => {
    switch (mode) {
      case "root":
        return "bg-primary-500";
      case "detail":
        return "bg-primary-blue-500";
      default:
        return "bg-primary-500";
    }
  };

  const generateRouterLink = (routerLink: string, type?: TabType) => {
    switch (mode) {
      case "root":
        return routerLink;
      case "detail":
        if (type === TabType.HOME) return routerLink;
        return `/projects/${projectId}${routerLink}`;
      default:
        return routerLink;
    }
  };

  const generateActiveStyle = () => {
    switch (mode) {
      case "root":
        return "bg-primary-250";
      case "detail":
        return "bg-primary-blue-250";
      default:
        return "bg-primary-250";
    }
  };

  const generateDividerStyle = () => {
    switch (mode) {
      case "root":
        return "bg-primary-600";
      case "detail":
        return "bg-primary-blue-600";
      default:
        return "bg-primary-600";
    }
  };

  return (
    <div
      className={`flex flex-col gap-[65px] sidebar h-screen items-center overflow-auto ${generateBgStyle()}`}
    >
      <div className="flex flex-col gap-11 items-center">
        <div className="logo pt-8">
          <img
            src="/assets/images/arigatomax-white-logo.svg"
            alt="img"
            width={62}
            height={68}
          />
          <p className="text-white font-medium text-[11px] text-center">
            ArigatoMax
          </p>
        </div>

        <div className="flex flex-col gap-7 items-center rounded-lg">
          {mode === "detail" && (
            <>
              <NavLink
                className={({ isActive }) => {
                  return isActive ? `active ${generateActiveStyle()}` : "";
                }}
                to={"/home"}
              >
                <div className="bg-white p-3 rounded-lg shadow-black-500">
                  <p className="text-primary-blue-500 font-bold text-base leading-4">
                    ホームへ
                  </p>
                </div>
              </NavLink>
            </>
          )}
          <div
            className={`divider rounded-2xl ${generateDividerStyle()}`}
          ></div>

          <div className="flex flex-col gap-5">
            {tabs.map((tab, index) => (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? `active ${generateActiveStyle()}` : "";
                }}
                to={generateRouterLink(tab.routerLink, tab?.type)}
                key={index}
              >
                <div className="sidebar-tab flex gap-1 items-center py-1.5 px-2">
                  <img src={tab.imgSrc} alt="img" width={20} height={20} />
                  <p className="text-white font-medium text-base leading-5">
                    {tab.name}
                  </p>
                </div>
              </NavLink>
            ))}
          </div>

          <div
            className={`divider rounded-2xl ${generateDividerStyle()}`}
          ></div>

          <div className="px-4 w-[152px]">
            <p className="text-white text-base font-bold leading-[1.2rem]">
              ユーザー名
            </p>
            <p className="text-white text-base font-medium leading-[1.2rem]">
              {userName}
            </p>
          </div>
        </div>
      </div>

      <div
        className="flex items-center gap-1 cursor-pointer w-[152px] px-4 pb-8"
        onClick={handleLogout}
      >
        <img src="/assets/images/logout.svg" alt="" />
        <p className="text-white text-base font-medium leading-[1.2rem]">
          ログアウト
        </p>
      </div>
    </div>
  );
};

export default SideBar;
