import { ReactNode, TableHTMLAttributes, useMemo } from 'react';

import { SortOrderEnum } from '@/common/enums';
import { ITableColumn } from '@/common/types';
import { cn } from '@/lib/utils';

export type TableProps<T> = {
  columns: ITableColumn<T>[];
  rows: T[];
  customNullDataText?: string | ReactNode;
  layoutClassName?: string;
  sortField?: keyof T;
  sortOrder?: SortOrderEnum;
  onSort?: (newSortField: keyof T, newSortOrder: SortOrderEnum) => void;
} & TableHTMLAttributes<HTMLTableElement>;

export const AppTable = <T,>({
  columns,
  rows,
  customNullDataText = 'データ無し',
  layoutClassName,
  sortField,
  sortOrder,
  onSort,
}: TableProps<T>) => {
  const isSortAscending = useMemo(() => {
    return sortOrder === SortOrderEnum.Asc;
  }, [sortOrder]);

  const handleSort = (column: ITableColumn<T>) => {
    if (!column.field || !column.sortable || !onSort) return;
    let newSortOrder = SortOrderEnum.Asc;
    if (sortField === column.field) {
      newSortOrder = isSortAscending ? SortOrderEnum.Desc : SortOrderEnum.Asc;
    }
    onSort(column.field, newSortOrder);
  };

  return (
    <div
      className={cn(
        'flex max-h-table border-separate flex-col overflow-clip rounded-xl border border-outline-200 bg-white px-4 py-9',
        layoutClassName,
      )}
    >
      <table className="w-full table-fixed">
        <thead className="sticky top-0">
          <tr>
            {columns.map((column, indexColumn) => (
              <th
                key={indexColumn}
                className={cn('p-2.5', column.headerClassName, column.sortable && 'cursor-pointer')}
                onClick={() => handleSort(column)}
              >
                <span className={cn('flex w-full items-center justify-start gap-3')}>
                  <span>{column.label}</span>
                  {column.sortable && column.field === sortField && (
                    <span
                      className={cn('select-none transition-transform duration-200', isSortAscending && 'rotate-180')}
                    >
                      <img src="/assets/images/down-icon.svg" alt="sort" className="w-5" />
                    </span>
                  )}
                </span>
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <div className="flex-1 overflow-y-auto">
        <table className="w-full table-fixed">
          <tbody>
            {rows.length === 0 && (
              <tr>
                <td
                  colSpan={columns.length}
                  className="truncate py-10 text-center text-2xl font-bold text-secondary-400"
                >
                  {customNullDataText}
                </td>
              </tr>
            )}
            {rows.map((row, indexRow) => (
              <tr key={indexRow} className="odd:bg-plate-300">
                {columns.map((column, indexColumn) => {
                  const { field, valueGetter } = column;
                  return (
                    <td
                      key={indexColumn}
                      className={cn('p-2 text-left text-base font-medium text-secondary-400', column.bodyClassName)}
                    >
                      {valueGetter ? valueGetter(row) : field ? row[field] : ''}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
