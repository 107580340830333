import React, { useEffect, useState } from "react";
import { Flex, Link, Text } from "@radix-ui/themes";
import './index.css';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import AppFormField from "@/components/atom/AppFormField";
import AppButton from "@/components/atom/Button/Button";
import { useAuth } from "@/contexts";
import { AuthApi } from "@/module/auth/api";
import TextField from "@/components/atom/TextField/TextField";
import * as toast from "@/common/helpers/toast";
import { handleException } from "@/common/exceptions/exception-handling";


type TwoFAVerificationProps = {
}
type TwoFAVerificationFormInputs = {
    tfaCode: string
}

const TwoFAVerification:  React.FC<TwoFAVerificationProps> = ({}) => {
    const navigate = useNavigate();
    const [isErrorLogin, setIsErrorLogin] = useState(false);
    const [searchParams] = useSearchParams();
    const { login } = useAuth();
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm<TwoFAVerificationFormInputs>()
      const onSubmit: SubmitHandler<TwoFAVerificationFormInputs> = async (data: TwoFAVerificationFormInputs) => {
        try {
            const response = await AuthApi.verifyTfaCode(searchParams.get('email') || '', data.tfaCode, searchParams.get('token') || '');
            const accessToken = response?.data?.accessToken;
            const refreshToken = response?.data?.refreshToken;
            if (accessToken && refreshToken) {
                await login({
                    token: accessToken,
                    refreshToken: refreshToken
                })
                navigate('/home');
            }
        } catch (error) {
            const { errorMessage } = handleException(error);
            if (errorMessage) {
                setIsErrorLogin(true);
            }
        }
      }
    const handleResendCode = async () => {
        try {
            await AuthApi.resendTwoFAVerification(searchParams.get('email') || '', searchParams.get('token') || '');
            toast.showSuccess('認証コードを再送信しました');
        } catch (error) {
            const { errorMessage } = handleException(error);
            errorMessage && toast.showError(errorMessage);
        }
    }

    useEffect(() => {
        const verifyToken = async () => {
            try {
                await AuthApi.verifyLogin(searchParams.get('token') || '');
            } catch (error) {
                const { errorMessage } = handleException(error);
                errorMessage && toast.showError(errorMessage);
            }
        }
        verifyToken();
    }, [])
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction={'column'} gap={'60px'}>
                <Flex className="login-content" direction={'column'} gap={'40px'}>
                    <Flex className="login-introduce text-left" direction={'column'} gap={'16px'}>
                        <p className="text-neutral-850 text-4xl font-bold">2段階認証</p>
                        <p className="text-secondary-500 font-medium text-xl leading-6">メールをご確認ください。認証コードの有効期限は3分間です。</p>
                    </Flex>
                    <Flex className="login-field text-left" direction={'column'} gap={'22px'}>
                        <Flex direction={'column'} gap={'4px'}>
                            <AppFormField
                                textLabel="認証コード"
                                required={true}
                                errorText={errors.tfaCode?.message}
                            >
                                <TextField {...register("tfaCode", {required : "必須項目です"})}
                                    placeholder="2段階認証" error={errors.tfaCode} />
                            </AppFormField>
                            {isErrorLogin ? <p className="error-login text-sm font-medium text-red-450 two-fa-error text-left">※コードが正しくありません</p> : null}
                        </Flex>
                    </Flex>
                    <Flex justify={'center'}><Text className="justify-items-center">認証コードを<Link className="login-link" onClick={handleResendCode}>再送信する</Link></Text></Flex>
                </Flex>
                <div className="m-auto">
                    <AppButton size="lg" text="ログイン" type="submit"/>
                </div>
            </Flex>
        </form>
    )
}

export default TwoFAVerification;