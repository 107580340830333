import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';

import { ELanguage } from '@/common/enums';

interface ProjectLanguageModeContextData {
  rootLanguageMode: ELanguage;
  languageMode: ELanguage;
  isRootLanguageMode: boolean;
  setLanguageMode: (newLanguageMode: ELanguage) => void;
}

interface ProjectLanguageModeProps {
  children: ReactNode;
}

const ProjectLanguageModeContext = createContext<ProjectLanguageModeContextData | undefined>(undefined);

export const useProjectLanguageMode = (): ProjectLanguageModeContextData => {
  const context = useContext(ProjectLanguageModeContext);
  if (context === undefined) {
    throw new Error('useProjectLanguageMode must be used within an ProjectLanguageModeProvider');
  }
  return context;
};

export const ProjectLanguageModeProvider: FC<ProjectLanguageModeProps> = ({ children }) => {
  const rootLanguageMode = ELanguage.Japanese;
  const [languageMode, setLanguageMode] = useState<ELanguage>(rootLanguageMode);

  const isRootLanguageMode = useMemo(() => {
    return languageMode === rootLanguageMode;
  }, [languageMode, rootLanguageMode]);

  const value = {
    rootLanguageMode,
    languageMode,
    isRootLanguageMode,
    setLanguageMode,
  };

  return <ProjectLanguageModeContext.Provider value={value}>{children}</ProjectLanguageModeContext.Provider>;
};
