import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import AppButton from "@/components/atom/Button/Button";
import { IInterviewsResponse } from "@/module/interviews/response/interviews.interface";
import { EInterviewLabelDetail, SEX_CODE } from "@/module/interviews/constants";
import { displayData } from "@/common/helpers";
import RowDetail from "./RowDetail";
import { format } from "date-fns";
import { FULL_DATE_FORMAT } from "@/common/constants/common.constants";
export interface IInformationPopupProps {
  openPopup: boolean;
  setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  currentData: IInterviewsResponse;
}

const InformationPopup = ({
  openPopup,
  setOpenPopup,
  currentData,
}: IInformationPopupProps) => {
  const { firstName, lastName, email, birthday, tel, sex, aboutMyself } =
    currentData;
  const mapDataToDisplay = () => {
    return [
      {
        label: EInterviewLabelDetail.FIRST_NAME,
        value: `${displayData(firstName)}`,
      },
      {
        label: EInterviewLabelDetail.LAST_NAME,
        value: `${displayData(lastName)}`,
      },
      {
        label: EInterviewLabelDetail.EMAIL,
        value: `${displayData(email)}`,
      },
      {
        label: EInterviewLabelDetail.DOB,
        value: `${displayData(birthday ? format(birthday, FULL_DATE_FORMAT): '')}`,
      },
      {
        label: EInterviewLabelDetail.PHONE,
        value: `${displayData(`+${tel}`)}`,
      },
      {
        label: EInterviewLabelDetail.SEX,
        value: `${displayData(SEX_CODE[sex as keyof typeof SEX_CODE])}`,
      },
    ];
  };

  return (
    <Dialog.Root open={openPopup}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content className=" overflow-y-auto data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[69vh] w-[29%] translate-x-[-50%] translate-y-[-50%] bg-white p-4 shadow-popup focus:outline-none rounded-2xl">
          <Dialog.Title className="text-neutral-850 m-0 text-xl font-bold flex justify-between items-center">
            応募者情報
          </Dialog.Title>
          <Dialog.Description className="text-neutral-850 mt-4 mb-6 text-base">
            <div className="border-[3px] border-outline-200 rounded-lg p-4 shadow-information-interview-card">
              <div className=" flex gap-4 flex-col">
                {mapDataToDisplay().map((item, index) => (
                  <RowDetail data={item} key={index} />
                ))}
                <div className="w-full border-b-border-blue border-b-[1px]">
                  <p className="text-primary-400 font-bold text-base leading-5 flex justify-starts mb-1">
                    {EInterviewLabelDetail.SEFT_INTRO}
                  </p>
                  <p className="text-neutral-850 font-bold text-sm leading-17px flex p-2 rounded-md border border-outline-200 text-ellipsis line-clamp-1 break-all">
                    {displayData(aboutMyself)}
                  </p>
                </div>
              </div>
            </div>
          </Dialog.Description>
          <AppButton
            onClick={() => setOpenPopup(false)}
            text={"閉じる"}
            custome="w-full"
          ></AppButton>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default InformationPopup;
