import { CheckedState } from '@radix-ui/react-checkbox';
import { TextField as Text } from '@radix-ui/themes';
import { debounce } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { SortOrderEnum } from '@/common/enums';
import { handleException } from '@/common/exceptions/exception-handling';
import { formatFullDate } from '@/common/helpers';
import * as toast from '@/common/helpers/toast';
import { ITableColumn } from '@/common/types';
import AppButton from '@/components/atom/Button/Button';
import { Checkbox } from '@/components/atom/Checkbox';
import { AppTable } from '@/components/atom/Table';
import TextField from '@/components/atom/TextField/TextField';
import Heading1 from '@/components/common/Heading1';
import { useFilter } from '@/contexts';
import { useAffiliate } from '@/contexts/AffiliateContext';
import { useLoading } from '@/hooks/useLoading';
import { AffiliateApi } from '@/module/affiliates/api';
import { IUsersBody } from '@/module/users/request/users.interface';
import { IUsersResponse } from '@/module/users/response/users.interface';

export const AffiliateUserSelectionPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentSearch = searchParams.get('search') || '';
  const currentSortField = (searchParams.get('sortField') as keyof IUsersResponse) || 'createdAt';
  const currentSortOrder = (searchParams.get('sortOrder') as SortOrderEnum) || SortOrderEnum.Desc;
  const [users, setUsers] = useState<IUsersResponse[]>([]);
  const [searchTerm, setSearchTerm] = useState(currentSearch);
  const [sortField, setSortField] = useState<keyof IUsersResponse>(currentSortField);
  const [sortOrder, setSortOrder] = useState<SortOrderEnum>(currentSortOrder);
  const { openLoading, closeLoading } = useLoading();
  const { checkedUserId, setCheckedUserId } = useAffiliate();
  const { updateCurrentFilters } = useFilter<IUsersBody>();

  const debouncedSearch = useRef(
    debounce(async (term: string) => {
      await fetchUsers({ search: term });
    }, 300),
  ).current;

  const columns: ITableColumn<IUsersResponse>[] = [
    {
      label: '選択',
      headerClassName: 'w-[11%]',
      bodyClassName: 'w-[11%]',
      valueGetter: (row) => (
        <Checkbox
          checked={checkedUserId === row.id}
          onCheckedChange={(isCheck) => handleCheckedChange(row.id, isCheck)}
        />
      ),
    },
    {
      field: 'userName',
      label: '名前',
      headerClassName: 'w-[30%]',
      bodyClassName: 'w-[30%] truncate',
      sortable: true,
    },
    {
      field: 'email',
      label: 'メールアドレス',
      headerClassName: 'w-[30%]',
      bodyClassName: 'w-[30%] truncate',
      sortable: true,
    },
    {
      field: 'nickName',
      label: 'ユーザー名',
      headerClassName: 'w-[14%]',
      bodyClassName: 'w-[14%] truncate',
      sortable: true,
    },
    {
      field: 'createdAt',
      label: '登録日',
      headerClassName: 'w-[15%]',
      bodyClassName: 'w-[15%] truncate',
      sortable: true,
      valueGetter: (row) => formatFullDate(row.createdAt),
    },
  ];

  const handleBack = () => {
    navigate(`/`);
  };

  const updateUrlParams = (params: IUsersBody) => {
    const newParams = new URLSearchParams(searchParams);

    if (params.search !== undefined) {
      newParams.set('search', params.search);
    }
    if (params.sortField !== undefined) {
      newParams.set('sortField', params.sortField);
    }
    if (params.sortOrder !== undefined) {
      newParams.set('sortOrder', params.sortOrder);
    }

    navigate(`/affiliate?${newParams.toString()}`);
  };

  const fetchUsers = async (params?: IUsersBody) => {
    try {
      openLoading();
      const response = await AffiliateApi.getUsers(params);
      setUsers(response.data);
    } catch (err) {
      const errorResult = handleException(err);
      toast.showError(errorResult.errorMessage ?? errorResult.errorMessages);
    } finally {
      closeLoading();
    }
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    updateUrlParams({ search: newSearchTerm });
    debouncedSearch(newSearchTerm);
  };

  const handleSort = (newSortField: keyof IUsersResponse, newSortOrder: SortOrderEnum) => {
    setSortField(newSortField);
    setSortOrder(newSortOrder);

    const params = {
      search: searchTerm,
      sortField: newSortField,
      sortOrder: newSortOrder,
    };

    updateUrlParams(params);
    fetchUsers(params);
  };

  const handleCheckedChange = (userId: string, isCheck: CheckedState) => {
    if (isCheck) {
      setCheckedUserId(userId);
    } else {
      setCheckedUserId(null);
    }
  };

  const handleClick = () => {
    if (!checkedUserId) {
      toast.showError('Please choose a user');
    } else {
      navigate(`/affiliate/create/form`);
      updateCurrentFilters({
        search: searchTerm,
        sortField,
        sortOrder,
      });
    }
  };

  useEffect(() => {
    fetchUsers({
      search: searchTerm,
      sortField,
      sortOrder,
    });
    setCheckedUserId(null);
  }, []);

  return (
    <div className="space-y-11 px-20 py-16">
      <Heading1 text="アフィリエイト - ユーザー選択" backAction={handleBack} />
      <TextField placeholder="メールアドレス検索" value={searchTerm} onChange={handleSearch}>
        <Text.Slot></Text.Slot>
        <Text.Slot>
          <img src="/assets/images/search-icon.svg" alt="" />
        </Text.Slot>
      </TextField>
      <AppTable
        layoutClassName="!max-h-[calc(100vh-25rem)]"
        columns={columns}
        rows={users}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={handleSort}
      />
      <div className="flex justify-center">
        <AppButton text="次へ" size="lg" onClick={handleClick} disabled={!checkedUserId} />
      </div>
    </div>
  );
};
