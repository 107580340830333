import { TextField as Text } from '@radix-ui/themes';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { SortOrderEnum } from '@/common/enums';
import { handleException } from '@/common/exceptions/exception-handling';
import * as toast from '@/common/helpers/toast';
import AppButton from '@/components/atom/Button/Button';
import TextField from '@/components/atom/TextField/TextField';
import Heading1 from '@/components/common/Heading1';
import { useLoading } from '@/hooks/useLoading';
import { InterviewsApi } from '@/module/interviews/api';
import { IInterviewsParams } from '@/module/interviews/request/interviews.interface';
import { IInterviewsResponse } from '@/module/interviews/response/interviews.interface';
import TableInterviews from './components/TableInterviews';

interface InterviewsPageProps {}

const InterviewsPage: React.FC<InterviewsPageProps> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentSearch = searchParams.get('search') || '';
  const currentSortField = (searchParams.get('sortField') as keyof IInterviewsResponse) || 'interviewDate';
  const currentSortOrder = (searchParams.get('sortOrder') as SortOrderEnum) || SortOrderEnum.Desc;
  const [interviews, setInterviews] = useState<IInterviewsResponse[]>([]);
  const [searchTerm, setSearchTerm] = useState(currentSearch);
  const [sortField, setSortField] = useState<keyof IInterviewsResponse>(currentSortField);
  const [sortOrder, setSortOrder] = useState<SortOrderEnum>(currentSortOrder);
  const { projectId } = useParams() || '';
  const { openLoading, closeLoading } = useLoading();

  const debouncedSearch = useRef(
    debounce(async (term: string) => {
      await fetchInterviews({ search: term });
    }, 300),
  ).current;

  const updateUrlParams = (params: IInterviewsParams) => {
    const newParams = new URLSearchParams(searchParams);

    if (params.search !== undefined) {
      newParams.set('search', params.search);
    }
    if (params.sortField !== undefined) {
      newParams.set('sortField', params.sortField);
    }
    if (params.sortOrder !== undefined) {
      newParams.set('sortOrder', params.sortOrder);
    }

    navigate(`/projects/${projectId}/interviews?${newParams.toString()}`);
  };

  const fetchInterviews = async (params?: IInterviewsParams) => {
    try {
      openLoading();
      const response = await InterviewsApi.getAllInterviews(projectId || '', params);
      setInterviews(response.data);
    } catch (error) {
      const { errorMessage } = handleException(error);
      errorMessage && toast.showError(errorMessage);
      return [];
    } finally {
      closeLoading();
    }
  };

  const handleSort = (newSortField: keyof IInterviewsResponse, newSortOrder: SortOrderEnum) => {
    setSortField(newSortField);
    setSortOrder(newSortOrder);

    const params = {
      search: searchTerm,
      sortField: newSortField,
      sortOrder: newSortOrder,
    };

    updateUrlParams(params);
    fetchInterviews(params);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    updateUrlParams({ search: newSearchTerm });
    debouncedSearch(newSearchTerm);
  };

  const onBack = () => {
    navigate(`/projects/${projectId}/dashboard`);
  };

  const handleExportCSV = async () => {
    if (!projectId) return;
    try {
      openLoading();
      await InterviewsApi.exportCsv(projectId);
      toast.showSuccess('CSV 出力が完了しました');
    } catch (error) {
      handleException(error);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    fetchInterviews({ search: searchTerm });
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <div className="px-20 py-16">
      <Heading1 text="面談一覧" backAction={onBack} />
      <div className="my-10 flex w-full gap-10">
        <div className="flex-1">
          <TextField placeholder="メールアドレス検索" value={searchTerm} onChange={(value) => onSearch(value)}>
            <Text.Slot></Text.Slot>
            <Text.Slot>
              <img src="/assets/images/search-icon.svg" alt="" />
            </Text.Slot>
          </TextField>
        </div>
        <AppButton text="CSV 出力" size="s-md" onClick={handleExportCSV} />
      </div>
      <div className="">
        <TableInterviews
          data={interviews}
          sortField={sortField}
          sortOrder={sortOrder}
          setData={setInterviews}
          onSort={handleSort}
        />
      </div>
    </div>
  );
};

export default InterviewsPage;
