import React, { memo } from "react";
import ImageUploading, {
  ImageUploadingPropsType,
} from "react-images-uploading";
import AppButton from "../Button/Button";
import ImageDisplay from "../ImageDisplay";

interface AppImageUploadingProps extends ImageUploadingPropsType {
  width?: string;
  height?: string;
  defaultValue?: File | string;
  disabled?: boolean;
}

const AppImageUploading: React.FC<AppImageUploadingProps> = (props) => {
  const {
    width = "w-[393px]",
    height = "h-[221px]",
    defaultValue,
    disabled = false,
    ...rest
  } = props;
  return (
    <ImageUploading {...rest} maxNumber={69} dataURLKey="data_url">
      {({ imageList, onImageUpload, isDragging, dragProps }) => (
        <div className="upload__image-wrapper relative w-fit">
          {!disabled && (
            <AppButton
              type="button"
              variant="outline"
              size="s-md"
              custome="px-[5px] text-[11px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:hover:bg-white"
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              <div className="flex gap-1 ">
                <img src={"/assets/images/camera-icon.svg"} alt="" />
                <span>画像を選択する</span>
              </div>
            </AppButton>
          )}
          {imageList.length > 0 ? (
            imageList.map((image, index) => (
              <div key={index} className={`image-item ${height} ${width}`}>
                <ImageDisplay
                  className="w-full h-full object-cover rounded-md"
                  url={
                    image.data_url
                      ? image.data_url
                      : "/assets/images/default-image.png"
                  }
                  alt=""
                />
              </div>
            ))
          ) : (
            <div className={`image-item ${height} ${width}`}>
              <ImageDisplay
                className="w-full h-full object-cover rounded-md"
                url={defaultValue || "/assets/images/default-image.png"}
                alt=""
              />
            </div>
          )}
        </div>
      )}
    </ImageUploading>
  );
};

export default memo(AppImageUploading);
