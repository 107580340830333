import React, { useCallback } from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';

import '../MultiStepProgressBar/MultiStepProgressBar.css';

const STEPS = ['概要', '本文', 'リターン', '表記'] as const;

interface IStepBarProps {
  currentStep?: number;
  completedSteps?: number[];
  onChangeStep?: (step: number) => void;
}

const StepBar: React.FC<IStepBarProps> = (props) => {
  const { currentStep = 1, completedSteps = [], onChangeStep } = props;
  const handleChangeStep = useCallback(
    (step: number) => {
      if (step !== currentStep) {
        onChangeStep?.(step);
      }
    },
    [currentStep, onChangeStep],
  );

  const stepColor = useCallback(
    (index: number) => {
      let classes = '';
      if (currentStep !== index + 1) {
        classes += 'cursor-pointer ';
        if (!completedSteps.includes(index + 1)) classes += 'bg-primary-900 text-primary-250 ';
        else classes += 'bg-primary-500 ';
      } else {
        if (!completedSteps.includes(index + 1)) classes += 'bg-white text-primary-250 ';
        else classes += 'bg-primary-900';
      }
      return classes;
    },
    [completedSteps, currentStep],
  );

  return (
    <ProgressBar percent={100}>
      {STEPS.map((item, index) => (
        <Step key={index}>
          {() => (
            <div className="flex flex-col items-center gap-1">
              <div
                className={`relative flex h-16 w-16 cursor-default items-center justify-center rounded-full border-4 border-primary-500 text-2xl font-bold text-primary-500 ${stepColor(index)} `}
                onClick={() => handleChangeStep(index + 1)}
              >
                {completedSteps.includes(index + 1) ? (
                  <img src="/assets/images/checked.svg" alt="checked" />
                ) : (
                  index + 1
                )}
              </div>
              <div className="absolute bottom-[-24px] text-base font-medium text-primary-500">{item}</div>
            </div>
          )}
        </Step>
      ))}
    </ProgressBar>
  );
};

export default StepBar;
