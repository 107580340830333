import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { FieldErrors, useForm, UseFormReturn } from 'react-hook-form';
import { v4 as uuidV4 } from 'uuid';

import { ELanguage } from '@/common/enums';
import { extractTranslatableProjectReturnFields } from '@/common/helpers/project';
import { useProjectLanguageMode } from '@/contexts';
import { IStepThreeFormItem } from '@/module/projectsList/project.interface';
import { STEP_THREE_LANGUAGE_SCHEMA, STEP_THREE_SCHEMA } from '@/module/projectsList/validations/stepThree.validation';

interface UseProjectReturnLanguageFormOptions {
  defaultValues: {
    [ELanguage.Japanese]: IStepThreeFormItem;
    [ELanguage.English]: IStepThreeFormItem;
  };
}

interface IUseProjectReturnLanguageForm {
  getCurrentFormValue: () => UseFormReturn<IStepThreeFormItem>;
  getAllFormValues: () => Record<ELanguage, IStepThreeFormItem>;
  getAllFormErrors: () => Record<ELanguage, FieldErrors<IStepThreeFormItem>>;
  setAllFormValues: (values: Partial<Record<ELanguage, Partial<IStepThreeFormItem>>>) => void;
  resetAllFormValues: () => void;
  syncFormValues: () => void;
  validateAllForms: () => Promise<boolean>;
}

export function useProjectReturnLanguageForm(
  options: UseProjectReturnLanguageFormOptions,
): IUseProjectReturnLanguageForm {
  const { languageMode, isRootLanguageMode } = useProjectLanguageMode();

  const jaForm = useForm<IStepThreeFormItem>({
    resolver: yupResolver(STEP_THREE_SCHEMA),
    defaultValues: options.defaultValues[ELanguage.Japanese],
  });

  const enForm = useForm<IStepThreeFormItem>({
    resolver: yupResolver(STEP_THREE_LANGUAGE_SCHEMA),
    defaultValues: options.defaultValues[ELanguage.English],
  });

  const forms = {
    [ELanguage.Japanese]: jaForm,
    [ELanguage.English]: enForm,
  };

  const getCurrentFormValue = () => {
    return forms[languageMode];
  };

  const getAllFormValues = () => {
    return {
      [ELanguage.Japanese]: jaForm.getValues(),
      [ELanguage.English]: enForm.getValues(),
    };
  };

  const getAllFormErrors = () => {
    return {
      [ELanguage.Japanese]: jaForm.formState.errors,
      [ELanguage.English]: enForm.formState.errors,
    };
  };

  const setAllFormValues = (values: Partial<Record<ELanguage, Partial<IStepThreeFormItem>>>) => {
    if (values[ELanguage.Japanese]) {
      jaForm.reset(values[ELanguage.Japanese]);
    }
    if (values[ELanguage.English]) {
      enForm.reset(values[ELanguage.English]);
    }
  };

  const validateAllForms = async (): Promise<boolean> => {
    const validationResults = await Promise.all([jaForm.trigger(), enForm.trigger()]);
    const isValid = validationResults.every((result) => result === true);
    return isValid;
  };

  const resetAllFormValues = () => {
    const defaultValues = {
      id: uuidV4(),
      isNft: false,
      isQuantitySelectable: false,
      isInterviewRequired: false,
    };
    jaForm.reset(defaultValues);
    enForm.reset(defaultValues);
  };

  const syncFormValues = useCallback(() => {
    enForm.reset({
      ...jaForm.getValues(),
      ...extractTranslatableProjectReturnFields(jaForm.getValues(), enForm.getValues()),
    });
  }, [jaForm, enForm]);

  useEffect(() => {
    if (!isRootLanguageMode) {
      syncFormValues();
    }
  }, [isRootLanguageMode, syncFormValues]);

  return {
    getCurrentFormValue,
    getAllFormValues,
    getAllFormErrors,
    setAllFormValues,
    resetAllFormValues,
    syncFormValues,
    validateAllForms,
  };
}
