import * as yup from 'yup';

import { ERROR_MESSAGE } from '@/common/constants/common.constants';
import { removeHtmlTags } from '@/common/helpers';

export const STEP_TWO_SCHEMA = yup.object().shape({
  content: yup
    .string()
    .test('is-empty-html', ERROR_MESSAGE.required, (value) => removeHtmlTags(value).length !== 0)
    .test(
      'content-min-30-characters',
      `30${ERROR_MESSAGE.minCharacter}、1000${ERROR_MESSAGE.maxCharacter}`,
      (value) => removeHtmlTags(value).length >= 30 && removeHtmlTags(value).length <= 1000,
    )
    .required(ERROR_MESSAGE.required),
});
