import React from 'react';
import { Control, Controller, FieldErrors, UseFormHandleSubmit } from 'react-hook-form';

import Alert from '@/components/atom/Alert';
import AppFormField from '@/components/atom/AppFormField';
import AppButton from '@/components/atom/Button/Button';
import AppTextArea from '@/components/atom/TextArea/TextArea';
import TextField from '@/components/atom/TextField/TextField';
import { useProjectLanguageMode } from '@/contexts';
import { EStatusProject } from '@/module/projectsList/constants';
import { IProjectForm } from '@/module/projectsList/project.interface';

interface IStepFourProps {
  onSubmitForm: () => void;
  onSaveDraft: () => void;
  createError?: string | string[];
  errorMsg?: string;
  errors: FieldErrors<IProjectForm>;
  control: Control<IProjectForm>;
  handleSubmit: UseFormHandleSubmit<IProjectForm, undefined>;
  status?: EStatusProject;
  syncFormValues: () => void;
  validateAllForms: () => Promise<boolean>;
  hasFormError: boolean;
}

export interface IStepFourFormData {
  companyName: string;
  businessAddress: string;
  necessaryExpenses: string;
  otherInformation: string;
  businessTel: string;
}

const StepFour: React.FC<IStepFourProps> = ({
  onSubmitForm,
  createError,
  onSaveDraft,
  errors,
  handleSubmit,
  control,
  status,
  syncFormValues,
  validateAllForms,
  hasFormError,
}) => {
  const { languageMode, isRootLanguageMode } = useProjectLanguageMode();

  const onSubmit = async () => {
    syncFormValues();
    const isValid = await validateAllForms();
    if (!isValid) {
      return;
    }
    onSubmitForm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full py-16">
      <div className="flex flex-col items-start space-y-14">
        <div className="mb-2 flex w-full flex-col gap-6">
          <div key={`companyName-${languageMode}`}>
            <AppFormField
              textLabel="販売事業者名"
              required={isRootLanguageMode}
              errorText={errors.companyName?.message}
            >
              <Controller
                control={control}
                name="companyName"
                render={({ field }) => (
                  <TextField error={errors.companyName} placeholder="映画鑑賞チケット" {...field} />
                )}
              />
            </AppFormField>
          </div>

          <div key={`businessAddress-${languageMode}`}>
            <AppFormField
              textLabel="事業者の所在地/住所"
              required={isRootLanguageMode}
              errorText={errors.businessAddress?.message}
            >
              <Controller
                control={control}
                name="businessAddress"
                render={({ field }) => (
                  <TextField error={errors.businessAddress} placeholder="所在地/住所" {...field} />
                )}
              />
            </AppFormField>
          </div>

          <div key={`businessTel-${languageMode}`}>
            <AppFormField
              textLabel="事業者の電話番号"
              required={isRootLanguageMode}
              errorText={errors.businessTel?.message}
            >
              <Controller
                control={control}
                name="businessTel"
                render={({ field }) => (
                  <TextField placeholder="09012345678" type="number" error={errors.businessTel} {...field} />
                )}
              />
            </AppFormField>
          </div>

          <div key={`necessaryExpenses-${languageMode}`}>
            <AppFormField
              textLabel="対価以外に必要な費用"
              required={isRootLanguageMode}
              errorText={errors.necessaryExpenses?.message}
            >
              <Controller
                control={control}
                name="necessaryExpenses"
                render={({ field }) => (
                  <TextField error={errors.necessaryExpenses} placeholder="対価以外に必要な費用を入力する" {...field} />
                )}
              />
            </AppFormField>
          </div>

          <div key={`otherInformation-${languageMode}`}>
            <AppFormField
              textLabel="その他記載事項"
              required={isRootLanguageMode}
              errorText={errors.otherInformation?.message}
            >
              <Controller
                control={control}
                name="otherInformation"
                render={({ field }) => (
                  <AppTextArea
                    custome="h-[125px]"
                    error={errors.otherInformation}
                    placeholder="その他記載事項を入力する"
                    {...field}
                  />
                )}
              />
            </AppFormField>
          </div>
        </div>

        {createError !== undefined || Object.keys(errors).length || hasFormError ? (
          <Alert variant="error" message={createError || '入力に不備があります。必須項目を全て入力してください'} />
        ) : undefined}

        <div className="flex gap-3">
          {(status === EStatusProject.DRAFT || status === undefined) && (
            <AppButton text="下書きを保存" variant="outline" size="lg" onClick={onSaveDraft} />
          )}
          <AppButton type="submit" text="保存" size="lg" />
        </div>
      </div>
    </form>
  );
};

export default StepFour;
