import { FC, useCallback, useState } from 'react';

import { ELanguage } from '@/common/enums';
import { useProjectReturnLanguageForm } from '@/hooks';
import { EStatusProject } from '@/module/projectsList/constants';
import { IProjectForm, IStepThreeFormItem } from '@/module/projectsList/project.interface';
import FormScreen from './components/FormScreen';
import ListScreen from './components/ListScreen';

interface StepThreeProps {
  onNextStep: (step: number) => void;
  onSaveDraft: () => void;
  data?: IStepThreeFormItem[];
  setCurrentScreen: React.Dispatch<React.SetStateAction<STEP3_SCREEN>>;
  currentScreen: STEP3_SCREEN;
  status?: EStatusProject;
  allFormValues: Record<ELanguage, IProjectForm>;
  setAllFormValues: (values: Partial<Record<ELanguage, Partial<IProjectForm>>>) => void;
}

export enum STEP3_SCREEN {
  list,
  form,
}

const StepThree: FC<StepThreeProps> = ({
  onNextStep,
  onSaveDraft,
  data,
  currentScreen,
  setCurrentScreen,
  status,
  allFormValues,
  setAllFormValues,
}) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const {
    getCurrentFormValue: getCurrentReturnFormValue,
    getAllFormValues: getAllReturnFormValues,
    getAllFormErrors: getAllReturnFormErrors,
    setAllFormValues: setAllReturnFormValues,
    resetAllFormValues: resetAllReturnFormValues,
    syncFormValues: syncReturnFormValues,
    validateAllForms: validateAllReturnForms,
  } = useProjectReturnLanguageForm({
    defaultValues: {
      [ELanguage.Japanese]: {} as IStepThreeFormItem,
      [ELanguage.English]: {} as IStepThreeFormItem,
    },
  });

  const handleCancelForm = () => {
    setCurrentScreen(STEP3_SCREEN.list);
  };

  const getUpdatedReturns = (language: ELanguage) => {
    const formValues = getAllReturnFormValues();
    const currentReturns = allFormValues[language]?.projectReturns || [];
    if (selectedId === null) {
      return [
        ...currentReturns,
        {
          ...formValues[language],
          sortIndex: currentReturns.length,
        },
      ];
    } else {
      return currentReturns.map((item) => (item.id === selectedId ? formValues[language] : item));
    }
  };

  const handleSubmitForm = () => {
    setAllFormValues({
      [ELanguage.Japanese]: {
        ...allFormValues[ELanguage.Japanese],
        projectReturns: getUpdatedReturns(ELanguage.Japanese),
      },
      [ELanguage.English]: {
        ...allFormValues[ELanguage.English],
        projectReturns: getUpdatedReturns(ELanguage.English),
      },
    });

    setCurrentScreen(STEP3_SCREEN.list);
  };

  const handleClickCreateButton = () => {
    setSelectedId(null);
    resetAllReturnFormValues();
    setCurrentScreen(STEP3_SCREEN.form);
  };

  const handleItemClick = (id: string) => {
    const selectedValues = {
      [ELanguage.Japanese]:
        allFormValues[ELanguage.Japanese].projectReturns.find((item) => item.id === id) || ({} as IStepThreeFormItem),
      [ELanguage.English]:
        allFormValues[ELanguage.English].projectReturns.find((item) => item.id === id) || ({} as IStepThreeFormItem),
    };
    setSelectedId(id);
    setAllReturnFormValues(selectedValues);
    setCurrentScreen(STEP3_SCREEN.form);
  };

  const handleNextStep = useCallback(() => {
    onNextStep(4);
  }, [onNextStep]);

  return (
    <div>
      {currentScreen === STEP3_SCREEN.list ? (
        <ListScreen
          onCreateButtonClick={handleClickCreateButton}
          onNextStep={handleNextStep}
          onItemClick={handleItemClick}
          onSaveDraft={onSaveDraft}
          data={data}
          status={status}
          setAllFormValues={setAllFormValues}
          allFormValues={allFormValues}
        />
      ) : (
        <FormScreen
          currentReturnFormValue={getCurrentReturnFormValue()}
          allReturnFormValues={getAllReturnFormValues()}
          allReturnFormErrors={getAllReturnFormErrors()}
          onSubmitForm={handleSubmitForm}
          onCancelForm={handleCancelForm}
          syncReturnFormValues={syncReturnFormValues}
          validateAllReturnForms={validateAllReturnForms}
        />
      )}
    </div>
  );
};

export default StepThree;
