import { RELOAD_MESSAGE } from '@/common/constants/common.constants';
import { useEffect } from 'react';
import { unstable_usePrompt } from 'react-router-dom';

export const useBeforeUnload = (hasUnsavedChanges: boolean, message: string = RELOAD_MESSAGE) => {
  // When navigate to other route.
  unstable_usePrompt({
    message: RELOAD_MESSAGE,
    when: hasUnsavedChanges,
  });

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.returnValue = message; // Standard way to display the dialog in some browsers
        return message; // For compatibility with older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges, message]);
};
