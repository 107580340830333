import { ERROR_MESSAGE, generateErrorLimitMsg, REGEX_ONLY_NUMBER } from '@/common/constants/common.constants';
import { removeHtmlTags } from '@/common/helpers';
import * as yup from 'yup';

export const FORM_SCHEMA = yup.object().shape({
  title: yup
    .string()
    .min(5, generateErrorLimitMsg(5, 50))
    .max(50, generateErrorLimitMsg(5, 50))
    .required(ERROR_MESSAGE.required),
  summary: yup
    .string()
    .min(30, generateErrorLimitMsg(30, 200))
    .max(200, generateErrorLimitMsg(30, 200))
    .required(ERROR_MESSAGE.required),
  thumbnailUrl: yup.mixed().required(ERROR_MESSAGE.required),
  targetApAmount: yup
    .string()
    .typeError(ERROR_MESSAGE.wrongFormat)
    .test('min', ERROR_MESSAGE.wrongFormat, (value) => {
      const num = Number(value);
      return num >= 0;
    })
    .required(ERROR_MESSAGE.required),
  startDate: yup.string().typeError(ERROR_MESSAGE.wrongFormat).required(ERROR_MESSAGE.required),
  endDate: yup.string().typeError(ERROR_MESSAGE.wrongFormat).required(ERROR_MESSAGE.required),
  practitionerName: yup
    .string()
    .min(3, generateErrorLimitMsg(3, 30))
    .max(30, generateErrorLimitMsg(3, 30))
    .required(ERROR_MESSAGE.required),
  practitionerProfile: yup
    .string()
    .min(50, generateErrorLimitMsg(50, 300))
    .max(300, generateErrorLimitMsg(50, 300))
    .required(ERROR_MESSAGE.required),
  practitionerThumbnailUrl: yup.mixed().required(ERROR_MESSAGE.required),
  xUrl: yup.string().url(ERROR_MESSAGE.wrongFormat).optional(),
  instagramUrl: yup.string().url(ERROR_MESSAGE.wrongFormat).optional(),

  content: yup
    .string()
    .test('is-empty-html', ERROR_MESSAGE.required, (value) => removeHtmlTags(value).length !== 0)
    .test(
      'content-min-30-characters',
      `30${ERROR_MESSAGE.minCharacter}、1000${ERROR_MESSAGE.maxCharacter}`,
      (value) => removeHtmlTags(value).length >= 30 && removeHtmlTags(value).length <= 1000,
    )
    .required(ERROR_MESSAGE.required),

  projectReturns: yup.array().required(ERROR_MESSAGE.required).min(1, ERROR_MESSAGE.required),

  companyName: yup.string().required(ERROR_MESSAGE.required),
  businessAddress: yup.string().required(ERROR_MESSAGE.required),
  necessaryExpenses: yup.string().required(ERROR_MESSAGE.required),
  otherInformation: yup.string().required(ERROR_MESSAGE.required).max(255, generateErrorLimitMsg(1, 255)),
  businessTel: yup
    .string()
    .required(ERROR_MESSAGE.required)
    .min(10, ERROR_MESSAGE.wrongFormat)
    .max(12, ERROR_MESSAGE.wrongFormat)
    .matches(REGEX_ONLY_NUMBER, ERROR_MESSAGE.wrongFormat),
});

export const FORM_LANGUAGE_SCHEMA = yup.object().shape({
  title: yup.string().max(50, generateErrorLimitMsg(5, 50)).default(''),
  summary: yup.string().max(200, generateErrorLimitMsg(30, 200)).default(''),
  thumbnailUrl: yup.mixed().required(ERROR_MESSAGE.required),
  targetApAmount: yup
    .string()
    .typeError(ERROR_MESSAGE.wrongFormat)
    .test('min', ERROR_MESSAGE.wrongFormat, (value) => {
      const num = Number(value);
      return num >= 0;
    })
    .required(ERROR_MESSAGE.required),
  startDate: yup.string().typeError(ERROR_MESSAGE.wrongFormat).required(ERROR_MESSAGE.required),
  endDate: yup.string().typeError(ERROR_MESSAGE.wrongFormat).required(ERROR_MESSAGE.required),
  practitionerName: yup.string().max(30, generateErrorLimitMsg(3, 30)).default(''),
  practitionerProfile: yup.string().max(300, generateErrorLimitMsg(50, 300)).default(''),
  practitionerThumbnailUrl: yup.mixed().required(ERROR_MESSAGE.required),
  xUrl: yup.string().url(ERROR_MESSAGE.wrongFormat).optional(),
  instagramUrl: yup.string().url(ERROR_MESSAGE.wrongFormat).optional(),

  content: yup
    .string()
    .test('is-empty-html', ERROR_MESSAGE.required, (value) => removeHtmlTags(value).length !== 0)
    .test(
      'content-min-30-characters',
      `1000${ERROR_MESSAGE.maxCharacter}`,
      (value) => removeHtmlTags(value).length <= 1000,
    )
    .default(''),

  projectReturns: yup.array().required(ERROR_MESSAGE.required).min(1, ERROR_MESSAGE.required),

  companyName: yup.string().default(''),
  businessAddress: yup.string().default(''),
  necessaryExpenses: yup.string().default(''),
  otherInformation: yup.string().max(255, generateErrorLimitMsg(1, 255)).default(''),
  businessTel: yup
    .string()
    .max(12, ERROR_MESSAGE.wrongFormat)
    .matches(REGEX_ONLY_NUMBER, ERROR_MESSAGE.wrongFormat)
    .default(''),
});
